import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import BlogPost from "../components/Blogpost";
import axios from "axios";
import { toast } from "react-toastify";
import Overlay from "../components/overlay";
import EmailTemplate from "./EmailTemplate";
import moment from "moment/moment";


export default function EmailTemplateTable() {
  const [showoverlay, setshowOverlay] = useState(false);
  const handleClose = () => setshowOverlay(false);
  const [Refresh, setRefresh] = useState(false);
  const [rawdata, setrawDate] = useState([]);
  const [Rowdata, setRowdata] = useState([]);
  const columnDefs = [
    {
      field: "id",
      headerName: "Id",
      filter: "agNumberColumnFilter",
      suppressMenu: false,
      minWidth: 100,
      flex: 0,
      width: 100,
      suppressSizeToFit: false,
    },
    {
      field: "name",
      headerName: " Name",
      filter: "agTextColumnFilter",
      suppressMenu: true,
    
    },
    {
      field: "email_to",
      headerName: "To",
      filter: "agTextColumnFilter",
      suppressMenu: true,
    },
    {
      field: "email_from",
      headerName: "From",
      filter: "agNumberColumnFilter",
      suppressMenu: false,
      suppressSizeToFit: false,
    },
    {
      field: "subject",
      headerName: "Subject",
      filter: "agTextColumnFilter",
      suppressMenu: true,
    },
    {
      field: "date_created",
      headerName: "Date",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      cellRenderer: (params) => {
        return moment(params).format("MMM D YYYY")
      }
    },
    {
      field: "status",
      headerName: "Action",
      floatingFilter: false,
      suppressMenu: false,
      flex: 1,
      cellRenderer: (params) => ActionButtons(params),
      // minWidth: 350,
      // maxWidth: 400,
      suppressSizeToFit: true,
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      resizable: false,
      filter: true,
      flex: 1,
      floatingFilter: true,
      width: 100,
      minWidth: 100,
    };
  }, []);

  const handleShow = () => {
    setshowOverlay(true);
    setRowdata([])
  }

  const ActionButtons = (params) => {
    const Edit = () => {
      setshowOverlay(true)
      setRowdata(params.data)
      console.log('template data ',params.data)
      // console.log(params)
    }
    const Remove = async () => {
      const { id } = params.data
      const data = {
        id: id
      }

      await axios.delete(`${process.env.REACT_APP_BaseURL}/api/templete`, { data }).then((val) => {
        toast.success("successfully Remove")
        setRefresh(val)
      });
    }

    return <>
      <button type="button" onClick={Edit} className="px-2 py-1.5 bg-yellow-500 inline-flex rounded-md mr-2 text-white"><i className="fa fa-pencil"></i></button>
      <button type="button" onClick={Remove} className="px-2 py-1.5 bg-red-500 inline-flex rounded-md mr-2 text-white"><i className="fa fa-times"></i></button>
    </>
  }


  const listingData = async () => {
    const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/templete`);
    const res = await api.json()
    setrawDate(res);
  }
  useEffect(() => {
    listingData();
  }, [Refresh])

  return (
    <>
      <div className="p-4 grid grid-cols-12">
        <div className="column col-span-12 lg:col-span-8 mb-4">
          <h3>Email Templates</h3>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <div className="text-lg-end">
            <button type="button" className="btn-normal btn-green min-w-[10%] py-[10px] text-white" onClick={handleShow}>Create Email</button>
          </div>
        </div>

        <Overlay showoverlay={showoverlay} handleClose={handleClose} data={<EmailTemplate Rowdata={Rowdata} setshowOverlay={setshowOverlay}  setRefresh={setRefresh} />} />
        <div className="col-span-12">
          <AgGridReact
            className="ag-theme-alpine h100"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rawdata}
            pagination={true}
            alwaysShowHorizontalScroll={false}
            alwaysShowVerticalScroll={false}
          />
        </div>
      </div>
    </>
  )
}
