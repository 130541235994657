import * as yup from "yup";
export const schemas = yup.object().shape({
  ////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  email_to: yup.string().email("Invalid Email format").required("Field is Required!"),
  email_title: yup.string().required("Field is Required!"),
  email_subject: yup.string().required("Field is Required!"),
  email_from: yup.string().email("Invalid Email format").required("Field is Required!"),
  email_body: yup.string().required("Field is Required!"),
  
});
