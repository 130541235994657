import React from "react";
// import Dropdown from "react-bootstrap/Dropdown";

export default function AdminPanel() {
  return (
    <>
      <div className="mt-4 grid grid-cols-12 gap-4 px-4 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
        <div className="col-span-12">
          <div className="grid grid-cols- gap-3 sm:grid-cols-3 sm:gap-5 lg:grid-cols-2">
            <div className="rounded-lg bg-white p-4">
              <div className="flex justify-between space-x-1 items-center">
                <div>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  $67.6
                </p>
                <p className="mt-1 text-xs+">Total Listing</p>
                </div>
                <div>
                <i className="fa fa-line-chart text-2xl text-purple-500"></i>
                </div>
                
              </div>
              
            </div>
            <div className="rounded-lg bg-white p-4">
              <div className="flex justify-between items-center">
                <div>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  12.6
                </p>
                <p className="mt-1 text-xs+">Approved Listing</p>
                </div>
                <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-success"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  />
                </svg>
                </div>
               
                
              </div>
              
            </div>
            <div className="rounded-lg bg-white p-4">
              <div className="flex justify-between items-center">
                <div>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  143
                </p>
                <p className="mt-1 text-xs+">Pending Listing</p>
                </div>
                <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-warning"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                </div>
                
                
              </div>
              
            </div>
            <div className="rounded-lg bg-white p-4">
              <div className="flex justify-between items-center">
                <div>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  651
                </p>
                <p className="mt-1 text-xs+">Countries</p>
                </div>
                <div>
                <i className="fa fa-flag text-2xl text-orange-500"></i>
                </div>
              </div>
              
            </div>
            <div className="rounded-lg bg-white p-4">
              <div className="flex justify-between space-x-1 items-center">
                <div>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  46
                </p>
                <p className="mt-1 text-xs+">States</p>
                </div>
                <div>
                <i className="fa fa-map-marker text-2xl text-indigo-500"></i>
                </div>
              </div>
              
            </div>
            <div className="rounded-lg bg-white p-4">
              <div className="flex justify-between items-center">
                <div>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  80
                </p>
                <p className="mt-1 text-xs+">Users</p>
                </div>
                <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-error"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        {/* <div className="card col-span-12 lg:col-span-8 border-gray-100">
          <div className="flex items-center justify-between py-3 px-4">
            <h2 className="font-medium tracking-wide text-slate-700 text-sm">
              Projects Status
            </h2>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/">Action</Dropdown.Item>
                <Dropdown.Item href="/">Another action</Dropdown.Item>
                <Dropdown.Item href="/">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="grid grid-cols-1 gap-y-4 pb-3 sm:grid-cols-3">
            <div className="flex flex-col justify-between border-4 border-transparent border-l-info px-4">
              <div>
                <p className="text-base font-medium text-slate-600 dark:text-navy-100">
                  Listing
                </p>
                <p className="text-xs text-slate-400 dark:text-navy-300">
                  Design Learn Management System
                </p>
                <div className="badge mt-2 bg-cyan-200/50 text-info">
                  Best
                </div>
              </div>
              <div>
                <div className="mt-8">
                  <p className="font-inter">
                    <span className="text-2xl font-medium text-slate-600 dark:text-navy-100">
                      %55.
                    </span>
                    <span className="text-xs">23</span>
                  </p>
                  <p className="mt-1 text-xs">June 08, 2021</p>
                </div>
                <div className="mt-8 flex items-center justify-between space-x-2">
                  <div className="flex -space-x-3">
                    <div className="avatar h-8 w-8 hover:z-10">
                      <img
                        className="rounded-full ring ring-white"
                        src="/avatar/avatar-16.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="avatar h-8 w-8 hover:z-10">
                      <div className="is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white">
                        jd
                      </div>
                    </div>
                    <div className="avatar h-8 w-8 hover:z-10">
                      <img
                        className="rounded-full ring ring-white"
                        src="/avatar/avatar-20.jpg"
                        alt="avatar"
                      />
                    </div>
                  </div>
                  <button className="btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between border-4 border-transparent border-l-secondary px-4">
              <div>
                <p className="text-base font-medium text-slate-600 dark:text-navy-100">
                  Listing
                </p>
                <p className="text-xs text-slate-400 dark:text-navy-300">
                  Ecommerce Application
                </p>
                <div className="badge mt-2 bg-fuchsia-100 text-fuchsia-500">
                  Leading
                </div>
              </div>
              <div>
                <div className="mt-8">
                  <p className="font-inter">
                    <span className="text-2xl font-medium text-slate-600 dark:text-navy-100">
                      %14.
                    </span>
                    <span className="text-xs">84</span>
                  </p>
                  <p className="mt-1 text-xs">May 01, 2021</p>
                </div>
                <div className="mt-8 flex items-center justify-between space-x-2">
                  <div className="flex -space-x-3">
                    <div className="avatar h-8 w-8 hover:z-10">
                      <img
                        className="rounded-full ring ring-white dark:ring-navy-700"
                        src="avatar/avatar-16.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="avatar h-8 w-8 hover:z-10">
                      <div className="is-initial rounded-full bg-success text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                        uh
                      </div>
                    </div>
                    <div className="avatar h-8 w-8 hover:z-10">
                      <img
                        className="rounded-full ring ring-white dark:ring-navy-700"
                        src="avatar/avatar-14.jpg"
                        alt="avatar"
                      />
                    </div>
                  </div>
                  <button className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between border-4 border-transparent border-l-warning px-4">
              <div>
                <p className="text-base font-medium text-slate-600 dark:text-navy-100">
                  Listing
                </p>
                <p className="text-xs text-slate-400 dark:text-navy-300">
                  Create LMS design system on figma
                </p>
                <div className="mt-2 flex space-x-1.5">
                  <div className="badge bg-amber-100 text-warning">Top</div>
                  <div className="badge bg-amber-100 text-warning">No.1</div>
                </div>
              </div>
              <div>
                <div className="mt-8">
                  <p className="font-inter">
                    <span className="text-2xl font-medium text-slate-600 dark:text-navy-100">
                      %87.
                    </span>
                    <span className="text-xs">40</span>
                  </p>
                  <p className="mt-1 text-xs">September 16, 2021</p>
                </div>
                <div className="mt-8 flex items-center justify-between space-x-2">
                  <div className="flex -space-x-3">
                    <div className="avatar h-8 w-8 hover:z-10">
                      <img
                        className="rounded-full ring ring-white dark:ring-navy-700"
                        src="avatar/avatar-11.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div className="avatar h-8 w-8 hover:z-10">
                      <div className="is-initial rounded-full bg-red-600 text-xs uppercase text-white ring ring-white">
                        pm
                      </div>
                    </div>
                    <div className="avatar h-8 w-8 hover:z-10">
                      <img
                        className="rounded-full ring ring-white dark:ring-navy-700"
                        src="avatar/avatar-10.jpg"
                        alt="avatar"
                      />
                    </div>
                  </div>
                  <button className="btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <div className="flex items-center justify-between">
            <h2 className="font-medium tracking-wide text-slate-700 dark:text-navy-100 text-sm">
              Customer Satisfaction
            </h2>
            <Dropdown >
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/">Action</Dropdown.Item>
                <Dropdown.Item href="/">Another action</Dropdown.Item>
                <Dropdown.Item href="/">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="mt-3">
            <p className="mb-2">
              <span className="text-3xl text-slate-700 dark:text-navy-100">
                9.7
              </span>
              <span className="text-xs text-success">+2.1%</span>
            </p>
            <p className="text-xs mb-2">Performance score</p>
          </div>
          <div className="mt-4 flex h-2 space-x-1">
            <div className="w-5/12 rounded-full bg-primary dark:bg-accent"></div>
            <div className="w-2/12 rounded-full bg-success"></div>
            <div className="w-2/12 rounded-full bg-info"></div>

            <div className="w-2/12 rounded-full bg-warning"></div>
            <div className="w-1/12 rounded-full bg-red-600"></div>
          </div>

          <div className="is-scrollbar-hidden mt-4 min-w-full overflow-x-auto">
            <table className="w-full font-inter">
              <tbody>
                <tr>
                  <td className="whitespace-nowrap py-2">
                    <div className="flex items-center space-x-2">
                      <div className="h-3.5 w-3.5 rounded-full border-2 border-primary dark:border-accent"></div>
                      <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100 mb-0">
                        Exellent
                      </p>
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">
                    <p className="font-medium text-slate-700 mb-0">1 029</p>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">42%</td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-2">
                    <div className="flex items-center space-x-2">
                      <div className="h-3.5 w-3.5 rounded-full border-2 border-success"></div>
                      <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100 mb-0">
                        Very Good
                      </p>
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">
                    <p className="font-medium text-slate-700 dark:text-navy-100 mb-0">
                      426
                    </p>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">18%</td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-2">
                    <div className="flex items-center space-x-2">
                      <div className="h-3.5 w-3.5 rounded-full border-2 border-info"></div>
                      <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100 mb-0">
                        Good
                      </p>
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">
                    <p className="font-medium text-slate-700 dark:text-navy-100 mb-0">
                      326
                    </p>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">14%</td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-2">
                    <div className="flex items-center space-x-2">
                      <div className="h-3.5 w-3.5 rounded-full border-2 border-warning"></div>
                      <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100 mb-0">
                        Poor
                      </p>
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">
                    <p className="font-medium text-slate-700 dark:text-navy-100 mb-0">
                      395
                    </p>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">17%</td>
                </tr>
                <tr>
                  <td className="whitespace-nowrap py-2">
                    <div className="flex items-center space-x-2">
                      <div className="h-3.5 w-3.5 rounded-full border-2 border-error"></div>
                      <p className="font-medium tracking-wide text-slate-700 dark:text-navy-100 mb-0">
                        Very Poor
                      </p>
                    </div>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">
                    <p className="font-medium text-slate-700 dark:text-navy-100 mb-0">
                      129
                    </p>
                  </td>
                  <td className="whitespace-nowrap py-2 text-right">9%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
      {/* <div className="mt-4 grid grid-cols-12 gap-4 bg-white py-3 sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
        <div className="col-span-12 flex flex-col px-4 transition-all duration-[.25s] lg:col-span-3 lg:pr-0">
          <h2 className="text-base font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-xl">
            Top Sellers
          </h2>

          <p className="mt-3 grow">
            The top sellers is calculated based on the sales of a product and
            undergoes hourly updations.
          </p>

          <div className="mt-4">
            <p>Sales Growth</p>
            <div className="mt-1.5 flex items-center space-x-2">
              <div className="flex h-7 w-7 items-center justify-center rounded-full bg-green-300/50 text-success">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 11l5-5m0 0l5 5m-5-5v12"
                  />
                </svg>
              </div>
              <p className="text-base font-medium text-slate-700 dark:text-navy-100">
                $2,225.22
              </p>
            </div>
          </div>
        </div>
        <div className="is-scrollbar-hidden col-span-12 flex space-x-4 overflow-x-auto px-[var(--margin-x)] transition-all duration-[.25s] lg:col-span-9 lg:pl-0">
          <div className="card w-72 shrink-0 space-y-9 rounded-xl p-4 sm:px-5">
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-3">
                <div className="avatar">
                  <img
                    className="mask is-squircle"
                    src="avatar/avatar-5.jpg"
                    alt="avatar-5"
                  />
                </div>
                <div>
                  <p className="font-medium text-slate-700 line-clamp-1 mb-0">
                    Travis Fuller
                  </p>
                  <p className="text-xs text-slate-400 mb-0">Employee</p>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                  </button>
                  <div className="absolute top-0 right-0 -m-1 flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-primary px-1 text-tiny font-medium leading-none text-white dark:bg-accent">
                    2
                  </div>
                </div>
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                  <div className="absolute top-0 right-0 -m-1 flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-primary px-1 text-tiny font-medium leading-none text-white dark:bg-accent">
                    4
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between space-x-2">
              <div>
                <p className="text-xs">Sells</p>
                <p className="text-xl font-semibold text-slate-700">2 348</p>
              </div>
              <div>
                <p className="text-xs">Target</p>
                <p className="text-xl font-semibold text-slate-700">3 000</p>
              </div>
              <div>
                <p className="text-xs">Clients</p>
                <p className="text-xl font-semibold text-slate-700">78</p>
              </div>
            </div>
            <div className="grow">
              <div className="flex w-full space-x-1">
                <div className="h-2 w-4/12 rounded-full bg-primary"></div>
                <div
                  x-tooltip="'Chats Messages'"
                  className="h-2 w-3/12 rounded-full bg-success"
                ></div>
                <div
                  x-tooltip="'Emails'"
                  className="h-2 w-5/12 rounded-full bg-info"
                ></div>
              </div>
              <div className="mt-2 flex flex-wrap">
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-primary dark:bg-accent"></div>
                  <div className="flex space-x-1 text-xs leading-6">
                    <span className="font-medium text-slate-700">Calls</span>
                    <span>33%</span>
                  </div>
                </div>
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-success"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700">
                      Chat Messages
                    </span>
                    <span>17%</span>
                  </div>
                </div>
                <div className="mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-info"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700">Emails</span>
                    <span>50%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex space-x-2">
                <img
                  x-tooltip="'Award Level 1'"
                  className="h-6 w-6"
                  src="awards/award-19.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 2'"
                  className="h-6 w-6"
                  src="awards/award-2.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 3'"
                  className="h-6 w-6"
                  src="awards/award-5.svg"
                  alt="avatar"
                />
              </div>
              <button className="btn -mr-1.5 h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="card w-72 shrink-0 space-y-9 rounded-xl p-4 sm:px-5">
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-3">
                <div className="avatar">
                  <img
                    className="mask is-squircle"
                    src="avatar/avatar-18.jpg"
                    alt="avatar-18"
                  />
                </div>
                <div>
                  <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    Konnor Guzman
                  </p>
                  <p className="text-xs text-slate-400 dark:text-navy-300">
                    Employee
                  </p>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                  <div className="absolute top-0 right-0 -m-1 flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-primary px-1 text-tiny font-medium leading-none text-white dark:bg-accent">
                    3
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between space-x-2">
              <div>
                <p className="text-xs">Sells</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  1 451
                </p>
              </div>
              <div>
                <p className="text-xs">Target</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  2 000
                </p>
              </div>
              <div>
                <p className="text-xs">Clients</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  54
                </p>
              </div>
            </div>
            <div className="grow">
              <div className="flex w-full space-x-1">
                <div
                  x-tooltip="'Phone Calls'"
                  className="h-2 w-3/12 rounded-full bg-primary dark:bg-accent"
                ></div>
                <div
                  x-tooltip="'Chats Messages'"
                  className="h-2 w-7/12 rounded-full bg-success"
                ></div>
                <div
                  x-tooltip="'Emails'"
                  className="h-2 w-2/12 rounded-full bg-info"
                ></div>
              </div>
              <div className="mt-2 flex flex-wrap">
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-primary dark:bg-accent"></div>
                  <div className="flex space-x-1 text-xs leading-6">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Calls
                    </span>
                    <span>24%</span>
                  </div>
                </div>
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-success"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Chat Messages
                    </span>
                    <span>56%</span>
                  </div>
                </div>
                <div className="mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-info"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Emails
                    </span>
                    <span>20%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex space-x-2">
                <img
                  x-tooltip="'Award Level 1'"
                  className="h-6 w-6"
                  src="awards/award-1.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 2'"
                  className="h-6 w-6"
                  src="awards/award-6.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 3'"
                  className="h-6 w-6"
                  src="awards/award-9.svg"
                  alt="avatar"
                />
              </div>
              <button className="btn -mr-1.5 h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="card w-72 shrink-0 space-y-9 rounded-xl p-4 sm:px-5">
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-3">
                <div className="avatar">
                  <img
                    className="mask is-squircle"
                    src="avatar/avatar-6.jpg"
                    alt="avatar-6"
                  />
                </div>
                <div>
                  <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    Alfredo Elliott
                  </p>
                  <p className="text-xs text-slate-400 dark:text-navy-300">
                    Contractors
                  </p>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                  </button>
                  <div className="absolute top-0 right-0 -m-1 flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-primary px-1 text-tiny font-medium leading-none text-white dark:bg-accent">
                    4
                  </div>
                </div>
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-between space-x-2">
              <div>
                <p className="text-xs">Sells</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  423
                </p>
              </div>
              <div>
                <p className="text-xs">Target</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  500
                </p>
              </div>
              <div>
                <p className="text-xs">Clients</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  16
                </p>
              </div>
            </div>
            <div className="grow">
              <div className="flex w-full space-x-1">
                <div
                  x-tooltip="'Phone Calls'"
                  className="h-2 w-8/12 rounded-full bg-primary dark:bg-accent"
                ></div>
                <div
                  x-tooltip="'Chats Messages'"
                  className="h-2 w-2/12 rounded-full bg-success"
                ></div>
                <div
                  x-tooltip="'Emails'"
                  className="h-2 w-2/12 rounded-full bg-info"
                ></div>
              </div>
              <div className="mt-2 flex flex-wrap">
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-primary dark:bg-accent"></div>
                  <div className="flex space-x-1 text-xs leading-6">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Calls
                    </span>
                    <span>60%</span>
                  </div>
                </div>
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-success"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Chat Messages
                    </span>
                    <span>23%</span>
                  </div>
                </div>
                <div className="mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-info"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Emails
                    </span>
                    <span>17%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex space-x-2">
                <img
                  x-tooltip="'Award Level 2'"
                  className="h-6 w-6"
                  src="awards/award-14.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 3'"
                  className="h-6 w-6"
                  src="awards/award-13.svg"
                  alt="avatar"
                />
              </div>
              <button className="btn -mr-1.5 h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="card w-72 shrink-0 space-y-9 rounded-xl p-4 sm:px-5">
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-3">
                <div className="avatar">
                  <img
                    className="mask is-squircle"
                    src="avatar/avatar-11.jpg"
                    alt="avatar-11"
                  />
                </div>
                <div>
                  <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    Samantha Shelton
                  </p>
                  <p className="text-xs text-slate-400 dark:text-navy-300">
                    Contractors
                  </p>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                  </button>
                  <div className="absolute top-0 right-0 -m-1 flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-primary px-1 text-tiny font-medium leading-none text-white dark:bg-accent">
                    2
                  </div>
                </div>
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-between space-x-2">
              <div>
                <p className="text-xs">Sells</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  579
                </p>
              </div>
              <div>
                <p className="text-xs">Target</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  800
                </p>
              </div>
              <div>
                <p className="text-xs">Clients</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  24
                </p>
              </div>
            </div>
            <div className="grow">
              <div className="flex w-full space-x-1">
                <div
                  x-tooltip="'Phone Calls'"
                  className="h-2 w-4/12 rounded-full bg-primary dark:bg-accent"
                ></div>
                <div
                  x-tooltip="'Chats Messages'"
                  className="h-2 w-4/12 rounded-full bg-success"
                ></div>
                <div
                  x-tooltip="'Emails'"
                  className="h-2 w-4/12 rounded-full bg-info"
                ></div>
              </div>
              <div className="mt-2 flex flex-wrap">
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-primary dark:bg-accent"></div>
                  <div className="flex space-x-1 text-xs leading-6">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Calls
                    </span>
                    <span>30%</span>
                  </div>
                </div>
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-success"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Chat Messages
                    </span>
                    <span>36%</span>
                  </div>
                </div>
                <div className="mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-info"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Emails
                    </span>
                    <span>34%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex space-x-2">
                <img
                  x-tooltip="'Award Level 2'"
                  className="h-6 w-6"
                  src="awards/award-15.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 3'"
                  className="h-6 w-6"
                  src="awards/award-13.svg"
                  alt="avatar"
                />
              </div>
              <button className="btn -mr-1.5 h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="card w-72 shrink-0 space-y-9 rounded-xl p-4 sm:px-5">
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-3">
                <div className="avatar">
                  <img
                    className="mask is-squircle"
                    src="avatar/avatar-19.jpg"
                    alt="avatar-19"
                  />
                </div>
                <div>
                  <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    Derrick Simmons
                  </p>
                  <p className="text-xs text-slate-400 dark:text-navy-300">
                    Employee
                  </p>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-between space-x-2">
              <div>
                <p className="text-xs">Sells</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  6 541
                </p>
              </div>
              <div>
                <p className="text-xs+">Target</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  8 000
                </p>
              </div>
              <div>
                <p className="text-xs">Clients</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  145
                </p>
              </div>
            </div>
            <div className="grow">
              <div className="flex w-full space-x-1">
                <div
                  x-tooltip="'Phone Calls'"
                  className="h-2 w-6/12 rounded-full bg-primary dark:bg-accent"
                ></div>
                <div
                  x-tooltip="'Chats Messages'"
                  className="h-2 w-4/12 rounded-full bg-success"
                ></div>
                <div
                  x-tooltip="'Emails'"
                  className="h-2 w-2/12 rounded-full bg-info"
                ></div>
              </div>
              <div className="mt-2 flex flex-wrap">
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-primary dark:bg-accent"></div>
                  <div className="flex space-x-1 text-xs leading-6">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Calls
                    </span>
                    <span>55%</span>
                  </div>
                </div>
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-success"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Chat Messages
                    </span>
                    <span>30%</span>
                  </div>
                </div>
                <div className="mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-info"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Emails
                    </span>
                    <span>15%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex space-x-2">
                <img
                  x-tooltip="'Award Level 2'"
                  className="h-6 w-6"
                  src="awards/award-15.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 3'"
                  className="h-6 w-6"
                  src="awards/award-5.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 3'"
                  className="h-6 w-6"
                  src="awards/award-25.svg"
                  alt="avatar"
                />
              </div>
              <button className="btn -mr-1.5 h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="card w-72 shrink-0 space-y-9 rounded-xl p-4 sm:px-5">
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-3">
                <div className="avatar">
                  <img
                    className="mask is-squircle"
                    src="avatar/avatar-7.jpg"
                    alt="avatar-7"
                  />
                </div>
                <div>
                  <p className="font-medium text-slate-700 line-clamp-1 dark:text-navy-100">
                    Katrina West
                  </p>
                  <p className="text-xs text-slate-400 dark:text-navy-300">
                    Employee
                  </p>
                </div>
              </div>
              <div className="flex space-x-2">
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                      />
                    </svg>
                  </button>
                  <div className="absolute top-0 right-0 -m-1 flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-primary px-1 text-tiny font-medium leading-none text-white dark:bg-accent">
                    1
                  </div>
                </div>
                <div className="relative cursor-pointer">
                  <button className="btn h-7 w-7 bg-primary/10 p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:bg-accent-light/10 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4.5 w-4.5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div className="flex justify-between space-x-2">
              <div>
                <p className="text-xs">Sells</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  3 481
                </p>
              </div>
              <div>
                <p className="text-xs">Target</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  5 000
                </p>
              </div>
              <div>
                <p className="text-xs">Clients</p>
                <p className="text-xl font-semibold text-slate-700 dark:text-navy-100">
                  96
                </p>
              </div>
            </div>

            <div className="grow">
              <div className="flex w-full space-x-1">
                <div
                  x-tooltip="'Phone Calls'"
                  className="h-2 w-1/12 rounded-full bg-primary dark:bg-accent"
                ></div>
                <div
                  x-tooltip="'Chats Messages'"
                  className="h-2 w-5/12 rounded-full bg-success"
                ></div>
                <div
                  x-tooltip="'Emails'"
                  className="h-2 w-6/12 rounded-full bg-info"
                ></div>
              </div>
              <div className="mt-2 flex flex-wrap">
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-primary dark:bg-accent"></div>
                  <div className="flex space-x-1 text-xs leading-6">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Calls
                    </span>
                    <span>9%</span>
                  </div>
                </div>
                <div className="mr-4 mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-success"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Chat Messages
                    </span>
                    <span>41%</span>
                  </div>
                </div>
                <div className="mb-1 inline-flex items-center space-x-2 font-inter">
                  <div className="h-2 w-2 rounded-full bg-info"></div>
                  <div className="flex space-x-1 text-xs">
                    <span className="font-medium text-slate-700 dark:text-navy-100">
                      Emails
                    </span>
                    <span>50%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex space-x-2">
                <img
                  x-tooltip="'Award Level 2'"
                  className="h-6 w-6"
                  src="awards/award-1.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 3'"
                  className="h-6 w-6"
                  src="awards/award-24.svg"
                  alt="avatar"
                />
                <img
                  x-tooltip="'Award Level 3'"
                  className="h-6 w-6"
                  src="awards/award-30.svg"
                  alt="avatar"
                />
              </div>
              <button className="btn -mr-1.5 h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div> 
      <div className="mt-4 grid grid-cols-12 gap-4 px-4 transition-all duration-[.25s] sm:mt-5 sm:gap-5 lg:mt-6 lg:gap-6">
        <div className="card col-span-12 sm:col-span-6 border-gray-100">
          <div className="my-3 flex items-center justify-between px-4 sm:px-5">
            <h2 className="font-medium tracking-wide text-slate-700 text-sm">
              Bandwidth Report
            </h2>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/">Action</Dropdown.Item>
                <Dropdown.Item href="/">Another action</Dropdown.Item>
                <Dropdown.Item href="/">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="grid grid-cols-1 gap-4 px-4 sm:gap-5 sm:px-5 lg:grid-cols-2">
            <div className="rounded-lg border border-slate-150 p-4 dark:border-navy-600">
              <div className="flex justify-between">
                <div>
                  <span className="text-2xl font-medium text-slate-700 dark:text-navy-100">
                    393
                  </span>
                  <span className="text-xs">Mb</span>
                </div>
                <p className="text-xs+">HTTP Traffic</p>
              </div>

              <div className="progress mt-3 h-1.5 bg-slate-150 dark:bg-navy-500">
                <div className="is-active relative w-8/12 overflow-hidden rounded-full bg-success"></div>
              </div>
              <div className="mt-2 flex justify-between text-xs text-slate-400 dark:text-navy-300">
                <p>Monthly target</p>
                <p>17%</p>
              </div>
            </div>
            <div className="rounded-lg border border-slate-150 p-4 dark:border-navy-600">
              <div className="flex justify-between">
                <div>
                  <span className="text-2xl font-medium text-slate-700 dark:text-navy-100">
                    293
                  </span>
                  <span className="text-xs">Mb</span>
                </div>
                <p className="text-xs+">SMTP Traffic</p>
              </div>

              <div className="progress mt-3 h-1.5 bg-slate-150 dark:bg-navy-500">
                <div className="relative w-8/12 overflow-hidden rounded-full bg-warning"></div>
              </div>
              <div className="mt-2 flex justify-between text-xs text-slate-400 dark:text-navy-300">
                <p>Monthly target</p>
                <p>65%</p>
              </div>
            </div>
            <div className="rounded-lg border border-slate-150 p-4 dark:border-navy-600">
              <div className="flex justify-between">
                <div>
                  <span className="text-2xl font-medium text-slate-700 dark:text-navy-100">
                    293
                  </span>
                  <span className="text-xs">Mb</span>
                </div>
                <p className="text-xs+">FTP Traffic</p>
              </div>

              <div className="progress mt-3 h-1.5 bg-slate-150 dark:bg-navy-500">
                <div className="relative w-5/12 overflow-hidden rounded-full bg-secondary"></div>
              </div>
              <div className="mt-2 flex justify-between text-xs text-slate-400 dark:text-navy-300">
                <p>Monthly target</p>
                <p>79%</p>
              </div>
            </div>
            <div className="rounded-lg border border-slate-150 p-4 dark:border-navy-600">
              <div className="flex justify-between">
                <div>
                  <span className="text-2xl font-medium text-slate-700 dark:text-navy-100">
                    36
                  </span>
                  <span className="text-xs">Mb</span>
                </div>
                <p className="text-xs+">POP3 Traffic</p>
              </div>

              <div className="progress mt-3 h-1.5 bg-slate-150 dark:bg-navy-500">
                <div className="is-active relative w-4/12 overflow-hidden rounded-full bg-slate-500 dark:bg-navy-400"></div>
              </div>
              <div className="mt-2 flex justify-between text-xs text-slate-400 dark:text-navy-300">
                <p>Monthly target</p>
                <p>79%</p>
              </div>
            </div>
          </div>

          <div className="mt-4 flex grow items-center justify-between px-4 sm:px-5">
            <div className="flex items-center space-x-2">
              <p className="text-xs+">Performance</p>

              <p className="text-slate-800 dark:text-navy-100">3.2%</p>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-success"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 11l5-5m0 0l5 5m-5-5v12"
                />
              </svg>
            </div>
            <a
              href="/"
              className="border-b border-dotted border-current pb-0.5 text-xs+ font-medium text-primary outline-none transition-colors duration-300 hover:text-primary/70 focus:text-primary/70 dark:text-accent-light dark:hover:text-accent-light/70 dark:focus:text-accent-light/70">
              Download Report
            </a>
          </div>
        </div>
        <div className="card col-span-12 pb-4 sm:col-span-6 border-gray-100">
          <div className="my-3 flex items-center justify-between px-4 sm:px-5">
            <h2 className="font-medium tracking-wide text-slate-700 dark:text-navy-100 text-sm">
              Users Activity
            </h2>
            <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/">Action</Dropdown.Item>
                <Dropdown.Item href="/">Another action</Dropdown.Item>
                <Dropdown.Item href="/">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <ol className="timeline line-space px-4 [--size:1.5rem] sm:px-5">
            <li className="timeline-item">
              <div className="timeline-item-point rounded-full border-1 border-fuchsia-400 bg-white text-secondary">
                <i className="fa fa-user text-xs text-fuchsia-400"></i>
              </div>
              <div className="timeline-item-content flex-1 pl-4 sm:pl-8">
                <div className="flex flex-col justify-between pb-2 sm:flex-row sm:pb-0">
                  <p className="pb-2 font-medium leading-none text-slate-600 dark:text-navy-100 sm:pb-0">
                    User Changed
                  </p>
                  <span className="text-xs text-slate-400">12 minute ago</span>
                </div>
                <p className="py-1">John Doe changed his avatar</p>
                <div className="avatar mt-2 h-16 w-16">
                  <img
                    className="mask is-squircle"
                    src="avatar/avatar-19.jpg"
                    alt="avatar"
                  />
                </div>
              </div>
            </li>
            <li className="timeline-item">
              <div className="timeline-item-point rounded-full border-1 border-green-500 bg-white text-success">
                <i className="fa fa-leaf text-xs"></i>
              </div>
              <div className="timeline-item-content flex-1 pl-4 sm:pl-8">
                <div className="flex flex-col justify-between pb-2 sm:flex-row sm:pb-0">
                  <p className="pb-2 font-medium leading-none text-slate-600 dark:text-navy-100 sm:pb-0">
                    Design Completed
                  </p>
                  <span className="text-xs text-slate-400 dark:text-navy-300">
                    3 hours ago
                  </span>
                </div>
                <p className="py-1">
                  Robert Nolan completed the design of the CRM application
                </p>
                <a
                  href="/"
                  className="inline-flex items-center space-x-1 pt-2 text-slate-600 transition-colors hover:text-primary dark:text-navy-100 dark:hover:text-accent">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  <span>File_final.fig</span>
                </a>
                <div className="pt-2">
                  <a
                    href="/"
                    className="rounded-full border-1 border-fuchsia-400 bg-fuchsia-100 text-fuchsia-500 hover:bg-fuchsia-300 focus:bg-fuchsia-400 active:bg-fuchsia-300 px-2.5 py-1 mr-2 text-xs font-semibold">
                    UI/UX
                  </a>

                  <a
                    href="/"
                    className="rounded-full border-1 border-sky-300 bg-sky-200/50 text-info hover:bg-sky-100 focus:bg-sky-300 active:bg-sky-200 px-2.5 py-1 mr-2 text-xs font-semibold">
                    CRM
                  </a>

                  <a
                    href="/"
                    className="rounded-full border-1 border-green-300 bg-green-100 text-success hover:bg-green-300 focus:bg-green-300 active:bg-green-300 px-2.5 py-1 text-xs font-semibold">
                    Dashboard
                  </a>
                </div>
              </div>
            </li>
            <li className="timeline-item">
              <div className="timeline-item-point rounded-full border-1 border-amber-400 bg-white text-warning">
                <i className="fa fa-share-alt text-xs"></i>
              </div>
              <div className="timeline-item-content flex-1 pl-4 sm:pl-8">
                <div className="flex flex-col justify-between pb-2 sm:flex-row sm:pb-0">
                  <p className="pb-2 font-medium leading-none text-slate-600 dark:text-navy-100 sm:pb-0">
                    ER Diagram
                  </p>
                  <span className="text-xs text-slate-400 dark:text-navy-300">
                    a day ago
                  </span>
                </div>
                <p className="py-1">Team completed the ER diagram app</p>
                <div>
                  <p className="text-xs text-slate-400 dark:text-navy-300">
                    Members:
                  </p>
                  <div className="mt-2 flex justify-between">
                    <div className="flex flex-wrap -space-x-2">
                      <div className="avatar h-7 w-7 hover:z-10">
                        <img
                          className="rounded-full ring ring-white dark:ring-navy-700"
                          src="avatar/avatar-16.jpg"
                          alt="avatar"
                        />
                      </div>

                      <div className="avatar h-7 w-7 hover:z-10">
                        <div className="is-initial rounded-full bg-info text-xs+ uppercase text-white ring ring-white dark:ring-navy-700">
                          jd
                        </div>
                      </div>

                      <div className="avatar h-7 w-7 hover:z-10">
                        <img
                          className="rounded-full ring ring-white dark:ring-navy-700"
                          src="avatar/avatar-20.jpg"
                          alt="avatar"
                        />
                      </div>

                      <div className="avatar h-7 w-7 hover:z-10">
                        <img
                          className="rounded-full ring ring-white dark:ring-navy-700"
                          src="avatar/avatar-8.jpg"
                          alt="avatar"
                        />
                      </div>

                      <div className="avatar h-7 w-7 hover:z-10">
                        <img
                          className="rounded-full ring ring-white dark:ring-navy-700"
                          src="avatar/avatar-5.jpg"
                          alt="avatar"
                        />
                      </div>
                    </div>
                    <button className="btn h-7 w-7 rounded-full bg-slate-150 p-0 font-medium text-slate-800 hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200/80 dark:bg-navy-500 dark:text-navy-50 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 rotate-45"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 11l5-5m0 0l5 5m-5-5v12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li className="timeline-item">
              <div className="timeline-item-point rounded-full border-1 border-red-400 bg-white text-red-400">
                <i className="fa fa-history text-xs "></i>
              </div>
              <div className="timeline-item-content flex-1 pl-4 sm:pl-8">
                <div className="flex flex-col justify-between pb-2 sm:flex-row sm:pb-0">
                  <p className="pb-2 font-medium leading-none text-slate-600 dark:text-navy-100 sm:pb-0">
                    Weekly Report
                  </p>
                  <span className="text-xs text-slate-400 dark:text-navy-300">
                    a day ago
                  </span>
                </div>
                <p className="py-1">The weekly report was uploaded</p>
              </div>
            </li>
          </ol>
        </div>
      </div>*/}
    </>
  );
}
