import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import ListingForm from "../components/Listingform";

import axios from "axios";
import { toast } from "react-toastify";
import Overlay from "../components/overlay";


function Listing() {
  const [showoverlay, setshowOverlay] = useState(false);
  const handleClose = () => setshowOverlay(false);

  const handleShow = () => {
    setshowOverlay(true);
    setRowdata([])
  }

  const [rawdata, setrawDate] = useState(null);

  const [Rowdata, setRowdata] = useState([]);

  const [Refresh, setRefresh] = useState(false);


  const columnDefs = [
    {
      field: "id",
      headerName: "ID",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      minWidth: 100,
      suppressSizeToFit: true,
      maxWidth: 120,
    },
    {
      field: "com_heading",
      headerName: "Title",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      minWidth: 300,
      maxWidth: 300,
      suppressSizeToFit: true,
      // cellRenderer: (params) => {
      //   return (
      //     <>
      //       {params.value.charAt(0).toUpperCase() + params.value.slice(1).replaceAll("-", " ")}
      //     </>
      //   )
      // },

    },
    {
      field: "logo",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      minWidth: 200,
      maxWidth: 200,
      suppressSizeToFit: true,
      cellRenderer: (params) => process.env.NODE_ENV === "development" ? <img className="w-14" src={`${process.env.REACT_APP_BaseURL}/extraimages/listing/${params.value}`} alt="img here" /> : <img src={`${process.env.REACT_APP_BaseURL}/public/extraimages/listing/${params.value}`} alt="logo here" />
    },

    // {
    //   field: "short_dec",
    //   headerName: "Desc",
    //   filter: "agNumberColumnFilter",
    //   suppressMenu: true,
    //   // minWidth: 250,
    //   // maxWidth: 250,
    //   suppressSizeToFit: true,
    //   cellRenderer: (params) => {
    //     return (
    //       <>
    //         <p dangerouslySetInnerHTML={{ __html: params.value && JSON.parse(params.value)}}></p>
    //       </>
    //     )
    //   },
    // },
    {
      field: "website_url",
      headerName: "URL",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      minWidth: 250,
      maxWidth: 250,
      suppressSizeToFit: true,
    },
    // {
    //   field: "tags",
    //   filter: "agNumberColumnFilter",
    //   suppressMenu: true,
    //   minWidth: 70,
    //   suppressSizeToFit: true,

    // },
    // {
    //   field: "reviews_id",
    //   headerName: "Reviews",
    //   filter: "agNumberColumnFilter",
    //   suppressMenu: true,
    //   minWidth: 70,
    //   suppressSizeToFit: true,

    // },
    // {
    //   field: "user_id",
    //   headerName: "User ID",
    //   filter: "agNumberColumnFilter",
    //   suppressMenu: true,
    //   minWidth: 70,
    //   suppressSizeToFit: true,

    // },
    // {
    //   field: "phone",
    //   filter: "agNumberColumnFilter",
    //   suppressMenu: true,
    //   minWidth: 70,
    //   suppressSizeToFit: true,

    // },
    // {
    //   field: "category_id",
    //   headerName: "Cate ID",
    //   filter: "agNumberColumnFilter",
    //   suppressMenu: true,
    //   minWidth: 70,
    //   suppressSizeToFit: true,

    // },
    // {
    //   field: "rating",
    //   filter: "agNumberColumnFilter",
    //   suppressMenu: true,
    //   minWidth: 70,
    //   suppressSizeToFit: true,

    // },
    // {
    //   field: "web_email",
    //   filter: "agNumberColumnFilter",
    //   suppressMenu: true,
    //   minWidth: 70,
    //   suppressSizeToFit: true,

    // },
    {
      field: "city",
      headerName: "City",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      // minWidth: 200,
      // maxWidth: 200,
      suppressSizeToFit: true,
      // cellRenderer: (params) => {
      //   return (
      //     JSON.stringify(params)

      //   )

      // }

    },
    {
      field: "category",
      headerName: "Categories",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      // minWidth: 200,
      // maxWidth: 200,
      suppressSizeToFit: true,
      // cellRenderer: (params) => JSON.parse(params.value).map((val)=> val)

    },
    {
      field: "status",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      minWidth: 120,
      maxWidth: 120,
      suppressSizeToFit: true,
      cellRenderer: (params) => <span>{parseInt(params.value) === 0 ? "New" : parseInt(params.value) === 1 ? "Active" : parseInt(params.value) === 201 && "Active"}</span>
    },
    {
      field: "Actions",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      minWidth: 200,
      maxWidth: 200,
      suppressSizeToFit: true,
      cellRenderer: (params) => HandleAction(params),
    },

  ];


  const HandleAction = (params) => {


    const Edit = () => {
      setshowOverlay(true)
      setRowdata(params.data)
    }
    const Remove = async () => {
      const { id, loc_id } = params.data
      const data = {
        loc_id: loc_id,
        id: id
      }

      await axios.delete(`${process.env.REACT_APP_BaseURL}/api/listing`, { data }).then((val) => {
        toast.success("successfully Remove")
        setRefresh(val)

      });
    }
    const Approved = async () => {
      const { id, status } = params.data

      await axios.put(`${process.env.REACT_APP_BaseURL}/api/actions/listing`, { id, status }).then((val) => {
        toast.success("successfully Approved")
        setRefresh(val)
      });
    }
    return <>
      <button onClick={Approved} className="px-2 py-1.5 bg-green-600 inline-flex rounded-md mr-2 text-white"><i className="fa fa-check"></i></button>
      {/* <button className="px-2 py-1.5 bg-red-400 inline-flex rounded-md mr-2 text-white"><i className="fa fa-ban"></i></button> */}
      <button className="px-2 py-1.5 bg-yellow-500 inline-flex rounded-md mr-2 text-white" onClick={Edit}><i className="fa fa-pencil"></i></button>
      <button className="px-2 py-1.5 bg-red-500 inline-flex rounded-md mr-2 text-white" onClick={Remove}><i className="fa fa-times"></i></button>
    </>
  }

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
      width: 100,
      minWidth: 100,
    };
  }, []);

  const listingData = async () => {
    const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/actions/listing`)
    const res = await api.json()
    setrawDate(res)

  }

  useEffect(() => {
    listingData();
  }, [Refresh])
  return (
    <>
      <div className="p-4 grid grid-cols-12">
        <div className="column col-span-12 lg:col-span-8 mb-4">
          <h3>Manage Company</h3>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <div className="text-lg-end">
            <button className="btn-normal btn-green min-w-[10%] py-[10px] text-white" onClick={handleShow}>Add Company</button>
          </div>
        </div>

        <Overlay showoverlay={showoverlay} handleClose={handleClose} data={<ListingForm Rowdata={Rowdata} setshowOverlay={setshowOverlay} setRefresh={setRefresh} />} />
        <div className="col-span-12">
          <AgGridReact
            className="ag-theme-alpine h100"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rawdata}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
            animateRows={true}
          />
        </div>
      </div>
    </>

  )
}

export default Listing;