import React, { useState } from "react";
// import Collapse from "react-bootstrap/Collapse";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function SideBar2({ children }) {
    const [toggled, setToggled] = useState(false);
  return (
    <>
    <ToastContainer/>
    <nav className="header before:bg-white">
        {/* App Header */}
        <div className="header-container relative h-full flex w-full bg-white px-2 py-2.5 items-center border-b border-slate-150">
        <div className="site-logo">
            <img
              src="logo.png"
              alt="logo"
              width="195"
              height="50"
              className="img-fluid"
            />
          </div>
          <div className="">
            <button className="sb-button menu-toggle bg-transparent" onClick={() => setToggled(!toggled)}>
                <i className="fa fa-bars text-xl"></i>
            </button>
          </div>
          <Link className="nav-link ml-auto" to="/">
            <i className="fa fa-fw fa-sign-out me-2 text-slate-700"></i>
            <span className="font-semibold">Logout</span>
          </Link>
          
        </div>
      </nav>
      <div className="flex h-screen">
        <Sidebar collapsed={toggled} backgroundColor="#fff" className="sidebar_admin" >
          <Menu
            menuItemStyles={{
              button: {
                // the active class will be added automatically by react router
                // so we can use it to style the active menu item
                [`&.active`]: {
                  backgroundColor: "#fff",
                  color: "#b6c8d9",
                },
              },
            }}
          >
            
            <MenuItem icon={<i className="fa fa-fw fa-dashboard text-[20px]" />} component={<Link to="/" />}>
              {" "}
              Dashboard
            </MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-plus-circle text-[20px]" />} component={<Link to="/listing" />}>Manage Companies</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-tags text-[20px]" />} component={<Link to="/categories" />}>Manage Categories</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-map-marker text-[20px]" />} component={<Link to="/location" />}>Manage Locations</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-users text-[20px]" />} component={<Link to="/users" />}>Manage Users</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-book text-[20px]" />} component={<Link to="/Blog" />}> Blog</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-plus-circle text-[20px]" />} component={<Link to="/ServicesPage" />}>Add Services for L.P</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-code text-[20px]" />} component={<Link to="/snippets" />}>Add Third Party Codes</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-arrow-circle-o-right text-[20px]" />} component={<Link to="/Seodata" />}> Meta Tags Nearme</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-database text-[20px]" />} component={<Link to="/AllSortData" />}> Data Sorting</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-envelope text-[20px]" />} component={<Link to="/emailTemplate" />}> Email Templates</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-code text-[20px]" />} component={<Link to="/customhtml" />}> Html Templates</MenuItem>
            <MenuItem icon={<i className="fa fa-fw fa-envelope text-[20px]" />} component={<Link to="/emaillogs" />}> Email Logs</MenuItem>
            {/* <MenuItem icon={<i className="fa fa-fw fa-star text-[20px]" />} component={<Link to="/reviews" />}>Manage Reviews</MenuItem> */}
          </Menu>
        </Sidebar>
        <main className="main-content w-full pb-8">
          
            {children}
        </main>
      </div>
    </>
  );
}
