

import Home from './pages/home';
import { Route, Routes  } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import 'bootstrap/dist/css/bootstrap.css';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import '../src/styles/site.css'
import './App.css';
import Listing from './pages/listing';
import Location from './pages/location';
import Categories from './pages/categories';
import Users from './pages/users';
import Reviews from './pages/reviews';
import Snippets from './pages/Snippets';
import SeoData from './pages/Seodata';
import SortData from './pages/AllSortData'
import ServicesPage from './pages/ServicesPage';
import Blog from './pages/Blog';
import EmailTemplate from './pages/EmailTemplate';
import EmailtemplateTable from './pages/EmailTemplateTable';
import EmailTemplateTable from './pages/EmailTemplateTable';
import CustomHtmlTable from './pages/CustomHtmlTable';
import EmailLogsTable from './pages/EmailLogs/EmailLogsTabls';

function App() {
  return (
    <Routes>
        <Route path="/" element={<Home/>} />
        <Route path='*' element={<Home/>}/>
        <Route path="/listing" element={<Listing/>} />
        <Route path="/location" element={<Location/>} />
        <Route path="/categories" element={<Categories/>} />
        <Route path="/users" element={<Users/>} />
        {/* <Route path="/reviews" element={<Reviews/>} /> */}
        <Route path="/Snippets" element={<Snippets/>} />
        <Route path="/Seodata" element={<SeoData/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/AllSortData" element={<SortData/>} />
        <Route path="/ServicesPage" element={<ServicesPage/>} />
        <Route path="/emailTemplate" element={<EmailTemplateTable/>} />
        <Route path="/customhtml" element={<CustomHtmlTable/>} />
        <Route path="/emaillogs" element={<EmailLogsTable/>} />
     </Routes>
    );
}

export default App;
