import React, { useEffect, useMemo, useRef, useState } from "react";
// import dynamic from "next/dynamic";

// const JoditEditor = dynamic(() => import("jodit-react"), {
//   ssr: false,
// });
import JoditEditor from "jodit-react"

export default function Editor({ error, values, setValue,Rowdata, state,watch, clearErrors, name }) {
  const editor = useRef(null);
  const [text, setText] = useState('')
  const config = {
    readonly: false,
    language: "en",
    beautyHTML: true,
    toolbarButtonSize: "normal",
    askBeforePasteHTML: true,
    statusbar: false,
    // uploader: {
    //   insertImageAsBase64URI: true,
    // },
    uploader: {
      // insertImageAsBase64URI: true,
      // imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'webp'],
      // url: `${process.env.REACT_APP_BaseURL}`
      insertImageAsBase64URI: false,
      url: `${process.env.REACT_APP_BaseURL}/api/storeFile`,
      format: 'json',
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif', 'webp'],
      filesVariableName: function (e) {
        return "content";
      },
      prepareData: function (data) {
        return data;
      },
      isSuccess: async function (resp) {
        return resp
      },
      process: function (resp) {
        const datas = {
          files: resp?.files?.content,
          path: resp?.fields.path,
          baseurl: `${process.env.REACT_APP_BaseURL}/extraimages/listing/`,
          error: resp?.fields.error,
          message: resp?.fields.message,
        };
        return datas
      },
      defaultHandlerSuccess: async function (data, resp) {
        if (data?.files[0].newFilename) {
          if (text) {
            const url = `${process.env.REACT_APP_BaseURL}/public/extraimages/listing/${data?.files[0].newFilename}`
            const img = `<img src=${url} alt="here is img"/>`
            await setText((prevContent) => `${prevContent + img}`);
            // setValue(`${name}`,`${text + img}`)
          } else {
            const url = `${process.env.REACT_APP_BaseURL}/public/extraimages/listing/${data?.files[0].newFilename}`
            const img = `<img src=${url} alt="here is img"/>`
            await setText(img);
            // setValue(`${name}`,img)
            // setValue(`${name}`,`${img}`)
          }

        }
      },
    },
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "ul",
      "ol",
      "font",
      "fontsize",
      "paragraph",
      "classSpan",
      "lineHeight",
      "superscript",
      "subscript",
      "file",
      "image",
      "source",
      "fullsize",
      "selectall",
    ],
  };

  function handleChange(value) {
    if (value) {
      setText(value);
      // setValue(`${name}`,`${value}`)
      if (error) {
        clearErrors(`${name}`)
      }
    }else{
      setText('');
    }
  }
  
  // const handleKeyDown = (event) => {
  //   if (event.key === 'Delete') {
  //     setText('');
  //     setValue(`${name}`, '');
  //   }
  // };

  useEffect(()=>{
    if(state){
      // setText(state)
      setValue(name,state)
    }
  },[Rowdata?.id])
  

  useMemo(()=>{
    setValue(name,text)
  },[text])
 

  return (
    <div className="jodit_editor_scroll">
      <JoditEditor
        ref={editor}
        value={state}
        config={config}
        // onKeyDown={handleKeyDown}
        // onChange={(e) => handleChange(e)}
        onBlur={(e) => handleChange(e)}
        tabIndex={1}
        
      />
    </div>
  );
}
