import React from 'react'

export default function Snippets() {
  return (
    <div className="p-4 grid grid-cols-12">
        <div className="col-span-12 mb-3">
            <h3>Add Snippets</h3>
          </div>
          <div className="col-span-12">
            <form className='bg-white p-3 rounded-md'>
              <div className='mb-3'>
                  <label>Add Header Code</label>
                  <textarea className='min-h-[150px!important] form-control'></textarea>
              </div>
              
              <div className='mb-3'>
                  <label>Add Footer Code</label>
                  <textarea className='min-h-[150px!important] form-control'></textarea>
                  
              </div>
              <div>
                <button className='btn-normal btn-green min-w-[8%] py-[10px] text-white'>Save</button>
              </div>
            </form>
          </div>
    </div>
  )
}
