import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import EmailLogs from "./EmailLogs";
import Overlay from "../../components/overlay";


export default function EmailLogsTable() {


    const [show, setShow] = useState(false);
    const [popUPData,setpopUpData] = useState();
    const handleClose2 = () => setShow(false);
    const handleShow2 = (data) => {
        setShow(true);
        setpopUpData(data);
    
    }

  const [showoverlay, setshowOverlay] = useState(false);
  const handleClose = () => setshowOverlay(false);
  const [Refresh, setRefresh] = useState(false);
  const [rawdata, setrawDate] = useState([]);
  const [Rowdata, setRowdata] = useState([]);
  const columnDefs = [
    
    {
        field: "cat_id",
        headerName: "category Id",
        filter: "agNumberColumnFilter",
        suppressMenu: false,
        minWidth: 100,
        flex: 0,
        width: 100,
        suppressSizeToFit: false,
      },
      {
        field: "title",
        headerName: "company Name",
        filter: "agTextColumnFilter",
        suppressMenu: false,
        minWidth: 300,
        // flex: 0,
        // width: 100,
        suppressSizeToFit: false,
        cellRenderer: (params) => params.value.replace("in {{city}}" , " ")
      },
      {
        field: "first_name",
        headerName: "First Name",
        filter: "agTextColumnFilter",
        suppressMenu: false,
        // minWidth: 100,
        // flex: 0,
        // width: 100,
        suppressSizeToFit: false,
      },
      {
        field: "last_name",
        headerName: "Last Name",
        filter: "agTextColumnFilter",
        suppressMenu: false,
        // minWidth: 100,
        // flex: 0,
        // width: 100,
        suppressSizeToFit: false,
      },
      {
        field: "email",
        headerName: "Email",
        filter: "agTextColumnFilter",
        suppressMenu: false,
        // minWidth: 100,
        // flex: 0,
        // width: 100,
        suppressSizeToFit: false,
      },
      {
        field: "phone",
        headerName: "Phone",
        filter: "agNumberColumnFilter",
        suppressMenu: false,
        // minWidth: 100,
        // flex: 0,
        // width: 100,
        suppressSizeToFit: false,
      },
    //   {
    //     field: "address",
    //     headerName: "Address",
    //     filter: "agTextColumnFilter",
    //     suppressMenu: false,
    //     minWidth: 100,
    //     flex: 0,
    //     width: 100,
    //     suppressSizeToFit: false,
    //   },
    //   {
    //     field: "city",
    //     headerName: "City",
    //     filter: "agTextColumnFilter",
    //     suppressMenu: false,
    //     minWidth: 100,
    //     flex: 0,
    //     width: 100,
    //     suppressSizeToFit: false,
    //   },
    //   {
    //     field: "state",
    //     headerName: "State",
    //     filter: "agTextColumnFilter",
    //     suppressMenu: false,
    //     minWidth: 100,
    //     flex: 0,
    //     width: 100,
    //     suppressSizeToFit: false,
    //   },
    //   {
    //     field: "message",
    //     headerName: "Message",
    //     filter: "agTextColumnFilter",
    //     suppressMenu: false,
    //     minWidth: 100,
    //     flex: 0,
    //     width: 100,
    //     suppressSizeToFit: false,
    //   },
    //   {
    //     field: "page_url",
    //     headerName: "Page URL",
    //     filter: "agTextColumnFilter",
    //     suppressMenu: false,
    //     minWidth: 100,
    //     flex: 0,
    //     width: 100,
    //     suppressSizeToFit: false,
    //   },
    {
      field: "date_created",
      headerName: "Date",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      cellRenderer: (params) => {
        return moment(params).format("MMM D YYYY")
      }
    },
    {
      field: "status",
      headerName: "Action",
      floatingFilter: false,
      suppressMenu: false,
      flex: 1,
      cellRenderer: (params) => ActionButtons(params),
      // minWidth: 350,
      // maxWidth: 400,
      suppressSizeToFit: true,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      resizable: false,
      filter: true,
      flex: 1,
      floatingFilter: true,
      width: 100,
      minWidth: 100,
    };
  }, []);

  const handleShow = () => {
    setshowOverlay(true);
    setRowdata([])
  }

  const ActionButtons = (params) => {
    const Edit = () => {
      setshowOverlay(true)
      setRowdata(params.data)
      // console.log(params)
    }
    const Remove = async () => {
      const { id } = params.data
      const data = {
        id: id
      }

      await axios.delete(`${process.env.REACT_APP_BaseURL}/api/send-email`, { data }).then((val) => {
        toast.success("successfully Remove")
        setRefresh(val)
      });
    }

    return <>
      <button type="button" onClick={()=>handleShow2(params.data)}  className="px-2 py-1.5 bg-dark inline-flex rounded-md mr-2 text-white"><i className="fa fa-eye"></i></button>
      <button type="button" onClick={Remove} className="px-2 py-1.5 bg-red-500 inline-flex rounded-md mr-2 text-white"><i className="fa fa-times"></i></button>
    </>
  }


  const listingData = async () => {
    const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/send-email`);
    const res = await api.json()
    setrawDate(res);
  }
  useEffect(() => {
    listingData();
  }, [Refresh])



    
  const modalInfo = {
    title: popUPData?.title,
    first_name: popUPData?.first_name,
    last_name: popUPData?.last_name,
    email:popUPData?.email,
    page_url:popUPData?.page_url,
    phone:popUPData?.phone,
    message:popUPData?.message
  }

  return (
    <>


      <Modal show={show} size="lg" onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Email Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            { modalInfo.title &&  Object.keys(modalInfo)?.map((key , index)=>{

         return (

             <div className=" row my-2" key={index}>

            <div className=" font-semibold text-black col-md-2">{key.replace("_"," ").toUpperCase()} : </div>
            <div className=" col-md-10">{[popUPData[key]?.replace("in {{city}}"," ")]}</div>

        </div>
              )
            })

                        
                        
}
            
            

        </Modal.Body>
        
      </Modal>
      <div className="p-4 grid grid-cols-12">
        <div className="column col-span-12 lg:col-span-8 mb-4">
          <h3>Email Logs</h3>
        </div>
        {/* <div className="col-span-12 lg:col-span-4">
          <div className="text-lg-end">
            <button type="button" className="btn-normal btn-green min-w-[10%] py-[10px] text-white" onClick={handleShow}>Create Html</button>
          </div>
        </div> */}

        {/* <Overlay showoverlay={showoverlay} handleClose={handleClose} data={<EmailLogs Rowdata={Rowdata} setshowOverlay={setshowOverlay}  setRefresh={setRefresh} />} /> */}
        <div className="col-span-12">
          <AgGridReact
            className="ag-theme-alpine h100"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rawdata}
            pagination={true}
            alwaysShowHorizontalScroll={false}
            alwaysShowVerticalScroll={false}
          />
        </div>
      </div>
    </>
  )
}
