import React, { useState, useEffect } from "react";
// import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemas } from "./emailTemplateSchema";
import Editor from "../components/editor";

import axios from "axios";

// export const URL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3000"
//     : "https://backlinkerr.com";

function EmailTemplate({ data,Rowdata,Refresh, setshowOverlay,setRefresh, setValues, emailState }) {

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemas),
    mode: "onChange",
  });




  // const watchs = watch() 
  const values = getValues();
  // const cookies = new Cookies();
  // const user_email = cookies.get("user");
  // const username = cookies.get("cookie");

  // console.log('rowData',Rowdata)



  useEffect(() => {
    if (Rowdata.id) {
      const {
        id,
        email_to,
        name,
        email_from,
        subject,
        message
      } = Rowdata;

      setValue("id", id);
      setValue("email_title", name);
      setValue("email_to", email_to);
      setValue("email_from", email_from);
      setValue("email_subject", subject);
      setValue("email_body", message);
      // setValue("description", data.description);
      // setValue("variables", variables);

    

    }
    // eslint-disable-next-line
  }, [Rowdata?.id]);

  // console.log(data , "here is data")

  // useEffect(() => {
  //   if (emailState?.menuStatus === "close") {
  //     reset((formValues) => ({
  //       ...formValues,
  //       email_title: "",
  //       email_to: "",
  //       email_from: "",
  //       email_subject: "",
  //       email_body: "",
  //     }));
  //   }
  //   // eslint-disable-next-line
  // }, [])


  // useEffect(() => {
  //   setValue("email", user_email);
  //   setValue("username", username);
  //   // eslint-disable-next-line
  // }, [cookies]);


 


  const onSubmit = async (datas) => {
    // console.log('state',datas)

   
      const sendData = {
        id: Rowdata?.id,
        name: datas.email_title,
        email_to: datas.email_to,
        email_from: datas.email_from,
        email_subject: datas?.email_subject,
        email_body: datas.email_body,
        status: 0,
      };
      const header_options = {
        method: Rowdata?.id?"PUT":"POST",
        mode: "cors",
        url: `${process.env.REACT_APP_BaseURL}/api/templete`,
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: sendData,
      };

      axios.request(header_options).then(function (responce) {
        // if(responce.status === 201){
        //   setRefresh(!Refresh)
        // }
        if (responce?.status === 201 || responce?.status === 200) {
          toast.success("Email templates updated sucessfully!");
          setshowOverlay(false)
          setRefresh(responce)
          reset((formValues) => ({
            ...formValues,
            email_title: "",
            email_to: "",
            email_from: "",
            email_subject: "",
            email_body: "",
          }));
          setValues(responce);
        }
      }).catch(function (error) {
        if (error?.response?.status === 409) {
          toast.error(error?.response?.data);
          // setError("url", {
          //   message:
          //     "You already added this website on backlinkerr please change website URL",
          // });
        } else if (error?.response?.status === 500) {
          toast.error(`Internal Server Error ${error}`);
        }
      });

      // setRefresh(!Refresh)
    

  };
  const copyCode = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        toast.success("Copy URL Successfully");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>

      <div className="py-0.5">
        {/* { values?.variables && values?.variables.map((val,index)=> <span key={index}>{val}</span>)} */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4>Email Templates</h4>
                <p>Please fill the all compulsory fields</p>
              </div>
              <hr className="my-4" />
                  <div className="col-lg-12">
                    <div
                      className={` text_felid ${errors.email_title ? "error" : ""
                        }`}
                    >
                      <label>
                        {errors.email_title ? (
                          <>
                            <i
                              className="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                            {errors?.email_title?.message}
                          </>
                        ) : (
                          <>Title</>
                        )}
                      </label>
                      <input
                        type="text"
                        {...register("email_title", { required: true })}
                        maxLength={70}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className={` text_felid ${errors.email_to ? "error" : ""
                        }`}
                    >
                      <label>
                        {errors.email_to ? (
                          <>
                            <i
                              className="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                            {errors?.email_to?.message}
                          </>
                        ) : (
                          <>To</>
                        )}
                      </label>
                      <input
                        type="email"
                        {...register("email_to", { required: true })}
                        maxLength={70}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className={` text_felid ${errors.email_from ? "error" : ""
                        }`}
                    >
                      <label>
                        {errors.email_from ? (
                          <>
                            <i
                              className="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                            {errors?.email_from?.message}
                          </>
                        ) : (
                          <>From</>
                        )}
                      </label>
                      <input
                        type="email"
                        {...register("email_from", { required: true })}
                        maxLength={70}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className={` text_felid ${errors.email_subject ? "error" : ""
                        }`}
                    >
                      <label>
                        {errors.email_subject ? (
                          <>
                            <i
                              className="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                            {errors?.email_subject?.message}
                          </>
                        ) : (
                          <>Subject</>
                        )}
                      </label>
                      <input
                        type="text"
                        {...register("email_subject", { required: true })}
                        maxLength={70}
                        placeholder=""
                      />
                    </div>
                  </div>
              
              
              <div className="col-md-12">
                <div className="row">

                  <div
                    className={`col-md-12 text_felid ${errors.email_body ? "error" : ""
                      }`}
                  >
                    <label>
                      {errors.email_body ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.email_body?.message}
                        </>
                      ) : (
                        <>Email Body: <div className="w-10/12">{data?.variables?.length && JSON.parse(data?.variables).map((val, i) => <span onClick={() => copyCode(`{{${val}}}`)} className="cursor-pointer text-base ml-2 bg-green-600 text-white font-light px-1 py-1 mb-2 inline-block rounded-sm" key={i}>{`{{${val}}}`}</span>)}</div></>
                      )}

                      

                    </label>
                    
                    {/* <input
                        type="text"
                        {...register("email_body", { required: true })}
                        maxLength={70}
                        placeholder="Enter your email body here"
                      /> */}
                    <Editor name={'email_body'} setValue={setValue} state={values.email_body} error={errors?.email_body} clearErrors={clearErrors} />
                  </div>
                  <div className="col-md-12">
                    <div className="flex justify-start items-center h-full">
                      <button
                        type="submit"
                        className="bg-green-500 mr-3  px-5 py-2 border-0 rounded-md text-white"
                      >
                        Submit
                      </button>
                      
                      <button
                        type="button"
                        onClick={() =>
                          setshowOverlay(false)
                        }
                        className="py-2 px-4 rounded-md text-white bg-gray-500 hover:bg-green-800 hover:border-green-800 mr-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default EmailTemplate;
