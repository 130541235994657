import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css"
import CateForm from "../components/Cateform";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { toast } from "react-toastify";


function Categories() {
  const [showoverlay, setshowOverlay] = useState(false);
  const [rawdata, setrawDate] = useState(null);
  const [Rowdata, setRowdata] = useState([]);
  const handleClose = () => setshowOverlay(false);
  const handleShow = () => {
    setshowOverlay(true)
    setRowdata([])
  };
 
  function createMarkup(c) {
    return { __html: c };
  }
  function RemoveTags(tags) {
    var htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    return tags.replace(htmlRegexG, "");
  }

  const [Refresh, setRefresh] = useState(false);
  const columnDefs = [
    {
      field: "id",
      headerName:"ID",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      suppressSizeToFit: true,
      minWidth: 120,
      maxWidth: 120,

    },
    {
      field: "name",
      headerName:"Name",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      // minWidth: 70,
      suppressSizeToFit: true,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "slug",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      // minWidth: 70,
      suppressSizeToFit: true,
      minWidth: 250,
      maxWidth: 250,
    },
    {
      field: "icon",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      // minWidth: 70,
      suppressSizeToFit: true,
      minWidth: 200,
      width: 200,
      cellRenderer: (params) => process.env.NODE_ENV === "development" ? <img className="w-14" src={`${process.env.REACT_APP_BaseURL}/extraimages/listing/${params.value}`} alt="img here"/> : <img src={`${process.env.REACT_APP_BaseURL}/public/extraimages/listing/${params.value}`} alt="logo here" />
    },
    {
      field: "content",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      // minWidth: 70,
      suppressSizeToFit: true,
    //   minWidth: 475,
    //   width: 475,
      cellRenderer: (params) => {
        return (
          <>
            {params?.value && <p className="limited_lines" dangerouslySetInnerHTML={createMarkup(RemoveTags(params?.value))}></p>}
          </>
        )
      },
   
    },
    // {
    //   field: "status",
    //   filter: "agNumberColumnFilter",
    //   suppressMenu: true,
    //   // minWidth: 70,
    //   suppressSizeToFit: true,
    //   minWidth: 120,
    //   width: 120,
    // },
    {
      field: "Actions",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      minWidth: 200,
      maxWidth: 200,
      suppressSizeToFit: true,
      cellRenderer: (params) => HandleAction(params),
    },
  ];

  const HandleAction = (params) => {

   
    const Edit = () => {
        setshowOverlay(true)
        setRowdata(params.data)
    }
    const Remove = async () => {
       const {id} = params.data
      const data = {
        id: id
      }

      await axios.delete(`${process.env.REACT_APP_BaseURL}/api/categories`, {data}).then((val)=>{
        toast.success("successfully Remove")
        setRefresh(val)
      });
    }
   
    return <>
      <button onClick={Edit} className="px-2 py-1.5 bg-yellow-500 inline-flex rounded-md mr-2 text-white"><i className="fa fa-pencil"></i></button>
      <button onClick={Remove} className="px-2 py-1.5 bg-red-500 inline-flex rounded-md mr-2 text-white"><i className="fa fa-times"></i></button>
    </>
  }

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
      width: 100,
      minWidth: 100,
    };
  }, []);

  const listingData = async () => {
    const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/categories`);
    const res = await api.json()
    setrawDate(res);
  }

  useEffect(() => {
    listingData();
      // return (()=>{
      //   listingData();
      // })
  }, [Refresh])
  return (
    <>
    <div className="p-4 grid grid-cols-12">
        <div className="column col-span-12 lg:col-span-8 mb-4">
            <h3>Manage Categories</h3>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <div className="text-lg-end">
                <button className="btn-normal btn-green min-w-[10%] py-[10px] text-white" onClick={handleShow}>Add Category</button>
            </div>
          </div>
      
      <Offcanvas placement="end" className='w-75' show={showoverlay} onHide={handleClose}>
        
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CateForm setshowOverlay={setshowOverlay} Rowdata={Rowdata && Rowdata} setRefresh={setRefresh}/>
          </Offcanvas.Body>
        </Offcanvas>

        <div className="col-span-12">
          <AgGridReact
            className="ag-theme-alpine h100"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rawdata}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
            animateRows={true}
          />
          
        </div>
      </div>
    </>
  )
}

export default Categories