import React, { useMemo, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { toast } from "react-toastify";
import Overlay from "../components/overlay";
import AddServices from "../components/addservices";

function ServicesPage() {
    const [rawdata, setrawDate] = useState([]);
    const [Rowdata, setRowdata] = useState(null);
    const [Refresh, setRefresh] = useState(false);
    const [showoverlay, setshowOverlay] = useState(false);
    const handleClose = () => setshowOverlay(false);

    const handleShow = () => {
        setshowOverlay(true);
        setrawDate([])
    }
    const columnDefs = [
        {
          field: "id",
          headerName:"ID",
          filter: "agNumberColumnFilter",
          suppressMenu: true,
          minWidth: 70,
          suppressSizeToFit: true,
        },
        {
            field: "name",
            headerName: "name",
            filter: "agTextColumnFilter",
            suppressMenu: true,
            minWidth: 70,
            suppressSizeToFit: true
        },
        {
            field: "image",
            headerName: "image",
            filter: "agTextColumnFilter",
            suppressMenu: true,
            minWidth: 70,
            suppressSizeToFit: true,
            // cellRenderer: (params) => params.value.name.charAt(0).toUpperCase() + params.value.name.slice(1).replaceAll("-", " "),

        },
        {
            field: "url",
            headerName: "URL",
            filter: "agNumberColumnFilter",
            suppressMenu: true,
            minWidth: 70,
            suppressSizeToFit: true
            // cellRenderer: (params) => params.value.name.charAt(0).toUpperCase() + params.value.name.slice(1).replaceAll("-", " "),
        },
        {
            field: "id",
            headerName: "Action",
            filter: "agNumberColumnFilter",
            suppressMenu: true,
            minWidth: 70,
            suppressSizeToFit: true,
            cellRenderer: (params) => HandleAction(params),
        },
    ];


    const HandleAction = (params) => {


        const Edit = () => {
          setshowOverlay(true)
          setrawDate(params.data)
        }
        const Remove = async () => {
          const { id } = params.data
          const data = {
            id: id
          }
    
          await axios.delete(`${process.env.REACT_APP_BaseURL}/api/actions/services`, { data }).then((val) => {
            toast.success("successfully Remove")
            setRefresh(val)
          });
        }
        
        return <>
          <button className="px-2 py-1.5 bg-yellow-500 inline-flex rounded-md mr-2 text-white" onClick={Edit}><i className="fa fa-pencil"></i></button>
          <button className="px-2 py-1.5 bg-red-500 inline-flex rounded-md mr-2 text-white" onClick={Remove}><i className="fa fa-times"></i></button>
        </>
      }

    const defaultColDef = useMemo(() => {
        return {
            editable: false,
            sortable: true,
            resizable: true,
            filter: true,
            floatingFilter: true,
            flex: 1,
            width: 100,
            minWidth: 100,
        };
    }, []);



    const listingData = async () => {
        const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/actions/services`);
        const res = await api.json();
        setRowdata(res);
    }

    useEffect(() => {
        listingData();
    }, [Refresh])


    return (
        <>
            <div className="p-4 grid grid-cols-12">
                <div className="column col-span-12 lg:col-span-8 mb-4">
                    <h3>Manage Services for Landing Page</h3>
                </div>
                
                <div className="col-span-12 lg:col-span-4">
                    <div className="text-lg-end">
                        <button className="btn-normal btn-green min-w-[10%] py-[10px] text-white" onClick={handleShow}>Add Service</button>
                    </div>
                </div>

                <Overlay showoverlay={showoverlay} handleClose={handleClose} data={<AddServices Rowdata={rawdata} setshowOverlay={setshowOverlay} setRefresh={setRefresh}/>} />

                <div className="col-span-12">
                    <AgGridReact
                        className="ag-theme-alpine h100"
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowData={Rowdata}
                        alwaysShowHorizontalScroll={true}
                        alwaysShowVerticalScroll={true}
                        animateRows={true}
                    />
                </div>
            </div>
        </>
    )
}

export default ServicesPage