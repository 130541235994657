import React from 'react'
import Offcanvas from "react-bootstrap/Offcanvas";
function Overlay({data,showoverlay,handleClose}) {
  return (
    <Offcanvas placement="end" className='w-75' show={showoverlay} onHide={handleClose}>

          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {data}  
          </Offcanvas.Body>
        </Offcanvas>
  )
}

export default Overlay