import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "../widgets/easytocrop"
import { toast } from "react-toastify";
import Upload from "rc-upload";
import imageCompression from 'browser-image-compression';
import axios from "axios";


export const Binary = ({ setValue, errors, name, Rowdata, image, clearErrors, index, setInputList, inputList, setImage }) => {




    const [files, setFile] = useState([])
    // const [fileResult, setResult] = useState([])
    const [onSuccess, setSuccess] = useState([]);
    const [show, setShow] = useState([])
    const [ShowCroping, setShowCroping] = useState(false);
    const [croppedImage, setCroppedImage] = useState(files);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    // const [imageses, setImageses] = useState({
    //     logo: "",
    //     image: "",
    //     icon: ""
    // });

    const [crop, setCrop] = useState({ y: 0, x: 0 });
    const [zoom, setZoom] = useState(1);
    // eslint-disable-next-line
    const [ratio, setRatio] = useState(name == 'mp_image' || name == 'image' ? 1920 / 800 : name == 'service' ? 9 / 6 : 5 / 5);


    const Attachment = {
        action: ``,
        accept: '.Tiff, .png, .jpeg, .pdf, .jpg',
        multiple: false,
        beforeUpload(file) {
            if (file.size <= 24e6) {
                return new Promise((resolve) => {
                    var reader = new FileReader()
                    reader.onload = async () => {
                        const options = {
                            maxSizeMB: 1,
                            maxWidthOrHeight: 1920,
                            useWebWorker: true,
                        }
                        const compressedFile = await imageCompression(file, options);
                        console.log('compressed ...',compressedFile)
                       
                        setShow({ file: compressedFile, document: reader.result })
                        setShowCroping(true)
                    }
                    reader.readAsDataURL(file)

                    resolve(file)
                })
            } else {
                toast.error('Please upload a file smaller than 24 MB')
            }
        },
        //
        onProgress(step, file) {
            if (file.size <= 2e6) {
                setTimeout(() => setSuccess(Math.round(step.percent)), 1000)
            }
        },
        onSuccess(step, file, result) {
            if (file.size <= 2e6) {
                toast.success('Upload Success')
                // setResult(result)
                setFile(step.percent)

                return file
            }
            return false
        },
        onError(err) {
            // // console.log('onError', err)
            toast.error('Server not found!')
        },
        customRequest({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials,
        }) {
            const FormFile1 = new FormData()
            // if (data) {
            //     Object.keys(data).forEach((key) => {
            //         FormFile1.append(key, data[key])
            //     })
            // }
            // FormFile1.append(filename, file)

            // return {
            //     abort() {
            //         // console.log('upload progress is aborted.')
            //     },
            // }
        },
    }
    const showCroppedImage = async () => {
        try {
            if (name == 'logo') {
                const logos = new File([show?.file], ".webp", {
                    type: "image/webp",
                });
                const logoimage = new FormData();
                console.log('logoimage', logoimage)
                logoimage.append("myImage", logos)
                setCroppedImage(show.document);
                setShowCroping(false);
                axios.post(`${process.env.REACT_APP_BaseURL}/api/storeFile`, logoimage).then(({ data }) => {
                    console.log('name ',name, ' newfilename ', data.newFilename)
                    setValue(name, data.newFilename)
                }).catch((error) => {
                    console.log(error)
                })
                clearErrors(name)
               
            } else {
                const cropped = await getCroppedImg(show.document, croppedAreaPixels);
                const res = await fetch(cropped);
                const buf = await res.arrayBuffer();
                const filep = new File([buf], ".webp", {
                    type: "image/webp",
                });

                setCroppedImage(cropped);
                setShowCroping(false);

                
                const formData = new FormData();
                formData.append("myImage", filep)
                
                if (name == 'icon') {
                    axios.post(`${process.env.REACT_APP_BaseURL}/api/storeFile`, formData).then(({ data }) => {
                        setValue(name, data.newFilename)
                    }).catch((error) => {
                        console.log(error)
                    })
                    clearErrors(name)
                } else if (name == 'image') {
                    axios.post(`${process.env.REACT_APP_BaseURL}/api/storeFile`, formData).then(({ data }) => {
                        setValue(name, data.newFilename)
                    }).catch((error) => {
                        console.log(error)
                    })
                    clearErrors(name)
                } else if (name == 'cityimage') {
                    // const updatedLocations = [...inputList];
                    // formData.append("cityimage", filep)
                    axios.post(`${process.env.REACT_APP_BaseURL}/api/storeFile`, formData).then(({ data }) => {
                        // updatedLocations[index][name] = data.newFilename;
                        // setValue(name, data.newFilename)
                        setImage(data.newFilename)
                    }).catch((error) => {
                        console.log(error)
                    })
                    clearErrors(name)
                } else if (name == 'mp_image') {
                    axios.post(`${process.env.REACT_APP_BaseURL}/api/storeFile`, formData).then(({ data }) => {
                        console.log('mp image ',data.newFilename)
                        setValue(name, data.newFilename)
                    }).catch((error) => {
                        console.log(error)
                    })
                    clearErrors(name)
                }
                else if (name == 'service') {
                    axios.post(`${process.env.REACT_APP_BaseURL}/api/storeFile`, formData).then(({ data }) => {
                        setValue("service", data.newFilename)
                    }).catch((error) => {
                        console.log(error)
                    })
                    clearErrors(name)
                }  
            }
        } catch (e) {
            console.error(e);
        }
    };
    const handle_Close = () => setShowCroping(false);

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }



    return (
        <>
            <div className={`${errors ? "Image-error" : ""} h-full`}>
                <Upload
                    className={` ${files ? files : 'successfully'}`}
                    {...Attachment}
                >
                    <label
                        htmlFor="image"
                        className="p-3 cursor-pointer text-center border-2 border-dashed h-[90%] rounded-md flex flex-col justify-center border-blue-400"
                    >
                        {croppedImage.length ? <img src={croppedImage} className="w-full" alt="logo here" /> :
                            image ? process.env.NODE_ENV === "development" ? <img src={`${process.env.REACT_APP_BaseURL}/extraimages/listing/${image}`} alt="logo here" /> : <img src={`${process.env.REACT_APP_BaseURL}/public/extraimages/listing/${image}`} alt="logo here" /> : !show.document ? <> {name != "cityimage" && <span className="fa fa-cloud-upload text-6xl text-blue-400 mb-1 inline-block"> </span>}
                                <span className="rounded-md text-white bg-blue-400 w-full py-2 px-3 block">Upload {name == "service" ? "Image" : name.replace("_", " ")}</span>
                            </> : <img src={croppedImage} alt="logo here" />}

                    </label>
                </Upload>
            </div>




            <Modal
                show={ShowCroping}
                onHide={handle_Close}
                animation={true}
                className="edit_profile_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="font-semibold text-xl">
                        Cropping {name} image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="h-full bg-white float-right">
                    <div className="float-left w-full max-h-max overflow-hidden mb-3">
                        {/* <button type="button" className="border-0 capitalize bg-gray-400 text-white mr-2 rounded-md mb-2" onClick={() => setRatio(5 / 5)}>portrait</button>
                        <button type="button" className="border-0 capitalize bg-blue-400 text-white rounded-md mb-2 mr-2" onClick={() => setRatio(1.8)}>landscape</button> */}

                        <div className="float-left w-full h-[50vh] relative">
                            {name == 'logo' ? show.document && <img src={show.document} className="max-w-full" alt="img here" /> : <Cropper
                                image={show.document}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                aspect={ratio}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            // objectFit="horizontal-cover"
                            />}
                        </div>
                        {/* <div className="controls relative z-50">
                                <input
                                    type="range"
                                    value={zoom}
                                    min={1}
                                    max={100}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e) => {
                                        setZoom(e.target.value)
                                    }}
                                    className="zoom-range"
                                />
                            </div> */}
                    </div>
                    <div className="d-flex w-full float-left">
                        <button
                            onClick={showCroppedImage}
                            className="bg-green-600 border-0 custom_radius text-sm hover_effect1 text-white rounded-md no-underline px-3 d-flex items-center mr-2.5"
                        >Save</button>

                        <button
                            onClick={handle_Close}
                            className=" transition-1 border-green-600 border-1 hover_effect1 border-solid bg-[transparent] hover:bg-green-600 text-sm text-green-600 hover:text-white rounded-md no-underline min-h-[38px] min-w-[80px]"
                        >
                            Cancel
                        </button>
                    </div>

                </Modal.Body>
            </Modal>

        </>
    )
}
