import React, { useMemo, useState,useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

function Users() {
  const [rawdata, setrawDate] = useState(null);
  // const [showoverlay, setshowOverlay] = useState(false);
  // const [Rowdata, setRowdata] = useState([]);
  // const handleClose = () => setshowOverlay(false);
  // const handleShow = () => {
  //   setshowOverlay(true)
  //   setRowdata([])
  // };
  const columnDefs = [
    {
      field: "user_id",
      headerName:"ID",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      minWidth: 70,
      suppressSizeToFit: true,
    },
    {
      field: "name",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      minWidth: 70,
      suppressSizeToFit: true,

    },
    {
      field: "email",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      minWidth: 70,
      suppressSizeToFit: true,

    },
    {
      field: "phone",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      minWidth: 70,
      suppressSizeToFit: true,

    },
    {
      field: "status",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      minWidth: 70,
      suppressSizeToFit: true,

    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
      width: 100,
      minWidth: 100,
    };
  }, []);

  const listingData = async () => {
    const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/users`);
    const res = await api.json()
    setrawDate(res);
  }

  useEffect(() => {
    listingData();
      // return (()=>{
      //   listingData();
      // })
  }, [])
  return (
    <>
      <div className="p-4 grid grid-cols-12">
        <div className="col-span-12 mb-4">
            <h3>Manage Users</h3>
          </div>
        <div className="col-span-12">
          <AgGridReact
            className="ag-theme-alpine h100"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rawdata}
            alwaysShowHorizontalScroll={true}
            alwaysShowVerticalScroll={true}
            animateRows={true}
          />
        </div>
      </div>
    </>
  )
}

export default Users