import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemas } from "../widgets/locationschema";
import { Binary } from "./binary";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect } from "react";

function AddLocation({ Rowdata, setshowOverlay, setRefresh }) {
    const [loader, setLoader] = useState(false)
    const {
        handleSubmit,
        register,
        reset,
        setValue,
        watch,
        control,
        resetField,
        getValues,
        clearErrors,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemas),
        mode: "onChange",
    });


    const onSubmit = async (data) => {


        const FormDatas = {
            id: Rowdata.id ? Rowdata.id : "",
            name: data.name,
            image: data.service,
            url: data.url,
            description: data.description,
        }

        const header_options = {
            method: Rowdata.id ? "PUT" : "POST",
            mode: "cors",
            url: `${process.env.REACT_APP_BaseURL}/api/addlocations`,
            headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            data: FormDatas,
        };

        axios.request(header_options).then(function (responce) {
            if (responce?.status === 200) {
                toast.success("location sucessfully Updated");
                reset({})
                setshowOverlay(false)
                setRefresh(responce)
            } else if (responce?.status === 201) {
                toast.success("location sucessfully created");
                reset({})
                setshowOverlay(false)
                setRefresh(responce)
            }
        }).catch(function (error) {
            toast.error(error?.response?.data);
        });

    }

    useEffect(() => {
        if (Rowdata?.id) {
            setValue("name", Rowdata.name)
            setValue("service", Rowdata.image)
            setValue("url", Rowdata.url)
            setValue("description", Rowdata.description)
        }
    }, [Rowdata?.id])
    return (
        <>
            <div className="py-0.5">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Add Locations</h4>
                                <p>Please fill the all compulsory fields</p>
                            </div>
                            <hr className="my-4" />


                            <div className={`col-md-3 mb-8 ${errors.image ? "Image-error" : ""}`}>
                                <Binary
                                    image={Rowdata.image}
                                    Rowdata={Rowdata}
                                    name='service'
                                    clearErrors={clearErrors}
                                    setValue={setValue}
                                    errors={errors.image}
                                />
                            </div>
                            <div className="col-md-9 mb-8">
                                <div className="row">

                                    <div
                                        className={`col-md-6 text_felid ${errors.name ? "error" : ""
                                            }`}
                                    >
                                        <label>
                                            {errors.name ? (
                                                <>
                                                    <i
                                                        className="fa fa-exclamation-triangle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {errors?.name?.message}
                                                </>
                                            ) : (
                                                <>
                                                    Name <span className="text-red-500">*</span>
                                                </>
                                            )}
                                        </label>
                                        <div className="position-relative text_felid">
                                            <input
                                                type="text"
                                                {...register("name", { required: true })}
                                                placeholder="Name"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`text_felid col-md-6 ${errors.url ? "error" : ""
                                            }`}
                                    >
                                        <label>
                                            {errors.url ? (
                                                <>
                                                    <i
                                                        className="fa fa-exclamation-triangle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {errors?.url?.message}
                                                </>
                                            ) : (
                                                <>
                                                    URL
                                                    {/* <span className="text-red-500">*</span> */}
                                                </>
                                            )}
                                        </label>
                                        <div className="position-relative text_felid">
                                            <input
                                                type="text"
                                                {...register("url", { required: true })}
                                                placeholder="URL"
                                            />
                                        </div>

                                    </div>
                                    <div
                                        className={`col-md-12 text_felid ${errors.description ? "error" : ""
                                            }`}
                                    >
                                        <label>
                                            {errors.description ? (
                                                <>
                                                    <i
                                                        className="fa fa-exclamation-triangle"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {errors?.description?.message}
                                                </>
                                            ) : (
                                                <>
                                                    Description <span className="text-red-500">*</span>
                                                </>
                                            )}
                                        </label>
                                        <div className="position-relative text_felid">
                                            <input
                                                type="text"
                                                {...register("description", { required: true })}
                                                placeholder="Description"
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-12">
                                <button type="submit" disabled={loader ? true : false} className="bg-green-500  px-5 py-2 border-0 rounded-md text-white">{loader ? <><i className="fa fa-spinner fa-spin fa-2x fa-fw"></i></> : "Submit"}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )

}
export default AddLocation