import React, { useState, useEffect, useMemo } from 'react'
import { Controller } from "react-hook-form";
import Select from "react-select";
import countries from "./countries.json"
import statess from "./states.json"
import citiese from "./cities.json"
import axios from 'axios';
import { toast } from 'react-toastify';
import { Binary } from '../binary';
import { Selectedcountry } from '../../location';

function LocSelection({ categories, inputList, setInputList, errors, clearErrors, setError, Rowdata, setcountries, setstatess, setcities, setValue, loader }) {
    const [listingdetails, setlistingdetails] = useState([]);
    const changeKeyObjects = (obj) => {
        // debugger
        let res = [];
        for (let i = 0; i < obj.length; i++) {
            let objs = {
                value: obj[i].id,
                label: obj[i].name,
                data: obj[i],
            };
            res.push(objs);
        }
        return res;
    };


    // const countriesOptions = countries && changeKeyObjects(countries);
    const countriesOptions = countries && changeKeyObjects(Selectedcountry);

    const [stateOptionses, setstateOptionses] = useState('')
    const [citiesOptionses, setcitiesOptionses] = useState('')
    // const [loader,setLoader] = useState(true)


    const options = [
        {
            label: "free",
            value: "1",
        },
        {
            label: "sponsored",
            value: "2",
        },
        {
            label: "headline",
            value: "3",
        },
    ]
    // useEffect(() => {
    //     if (CountiresOptionse?.value) {
    //         setstatess([])
    //         setcities([])
    //     }
    // }, [CountiresOptionse.value])
    // useEffect(() => {
    //     if (StatesOption?.value) {
    //         setcities([])
    //     }
    // }, [StatesOption.value])

    const GETDetails = async () => {
        const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/actions/${Rowdata.id}`);
        const res = await api.json()
        setlistingdetails(res)
        // setLoader(false)
    }

    // useEffect(() => {
    //     if (Rowdata.id) {
    //         GETDetails();
    //         let datas = []
    //         for (let index = 0; index < listingdetails.length; index++) {
    //             const listValue = listingdetails[index];

    //             const country = JSON.parse(listValue.country_id)
    //             const state = JSON.parse(listValue.state_id)
    //             const city = JSON.parse(listValue.city_id)
    //             const cat_id = JSON.parse(listValue.cat_id)
    //             const listlevel = JSON.parse(listValue.list_level)

    //             const selectedCountry = country.id && countriesOptions.filter((val) => parseInt(val.value) == parseInt(country.id));
    //             const [dataes] = country.id && statess.filter((val) => parseInt(val.id) == parseInt(country.id))
    //             listValue && setstateOptionses(changeKeyObjects(dataes?.states))
    //             const data = state.id && dataes?.states.filter((val) => parseInt(val.id) == parseInt(state.id))
    //             const Selstates = changeKeyObjects(data);
    //             const [{ states }] = country.id && citiese.filter((val) => parseInt(val.id) == parseInt(country.id))
    //             const [{ cities }] = state.id && states.filter((val) => parseInt(val.id) == parseInt(state.id))
    //             const citye = city.id && cities.filter((val) => parseInt(val.id) == parseInt(city.id))
    //             const citiesa = changeKeyObjects(citye)
    //             listValue && setcitiesOptionses(changeKeyObjects(cities))
    //             const filteredArray = categories && categories.filter((obj1) => parseInt(obj1.value) === parseInt(cat_id.id))
    //             const listlavel = options && options.filter((obj1) => parseInt(obj1.value) === parseInt(listlevel.id))

    //             datas.push({
    //                 country: selectedCountry.pop(),
    //                 state: Selstates.pop(),
    //                 city: citiesa.pop(),
    //                 id: listValue.id,
    //                 category: filteredArray && filteredArray.pop(),
    //                 listlevel: listlavel && listlavel.pop()
    //             })

    //         }
    //         setInputList(datas)
    //     }
    // }, [Rowdata.id, categories])
    useEffect(() => {
        if (Rowdata.id) {
            GETDetails();
        }
    }, [Rowdata.id])
    useEffect(() => {
        if (Rowdata.id) {
            // let datas = []
            // for (let index = 0; index < listingdetails.length; index++) {
            //     const listValue = listingdetails[index];

            //     const selectedCountry = listValue.country_id && countriesOptions.filter((val) => parseInt(val.value) == parseInt(listValue.country_id));
            //     const [dataes] = listValue.country_id && statess.filter((val) => parseInt(val.id) == parseInt(listValue.country_id))
            //     listValue && setstateOptionses(changeKeyObjects(dataes?.states))
            //     const data = listValue.state_id && dataes?.states.filter((val) => parseInt(val.id) == parseInt(listValue.state_id))
            //     const Selstates = changeKeyObjects(data);
            //     const [{ states }] = listValue.country_id && citiese.filter((val) => parseInt(val.id) == parseInt(listValue.country_id))
            //     const [{ cities }] = listValue.state_id && states.filter((val) => parseInt(val.id) == parseInt(listValue.state_id))
            //     const citye = listValue.city_id && cities.filter((val) => parseInt(val.id) == parseInt(listValue.city_id))
            //     const citiesa = changeKeyObjects(citye)
            //     listValue && setcitiesOptionses(changeKeyObjects(cities))
            //     const filteredArray = categories && categories.filter((obj1) => parseInt(obj1.value) === parseInt(listValue.cat_id))
            //     const listlavel = options && options.filter((obj1) => parseInt(obj1.value) === parseInt(listValue.list_level))

            //     datas.push({
            //         country: selectedCountry.pop(),
            //         state: Selstates.pop(),
            //         city: citiesa.pop(),
            //         id: listValue.id,
            //         category: filteredArray && filteredArray.pop(),
            //         listlevel: listlavel && listlavel.pop(),
            //         cityimage: JSON.parse(listValue?.json_data).city.image && JSON.parse(listValue?.json_data).city.image
            //     })

            // }
            // setInputList(datas)
            if (listingdetails.length) {
                let inputList2 = []

                for (let i = 0; i < listingdetails.length; i++) {
                    let it1 = listingdetails[i];
                    let fi = inputList2.findIndex(item => item.city_id == it1.city_id && it1.list_level == item.list_level)

                    if (fi === -1) {
                        it1.cat_ids = [it1.cat_id];
                        inputList2.push(it1)
                    } else {
                        inputList2[fi].cat_ids.indexOf(it1.cat_id) === -1 && inputList2[fi].cat_ids.push(it1.cat_id);
                    }
                }
                inputList = inputList2


                let datas = []
                if (inputList2) {
                    for (let index = 0; index < inputList2.length; index++) {
                        const listValue = inputList2[index];

                        const selectedCountry = listValue.country_id && countriesOptions.filter((val) => parseInt(val.value) == parseInt(listValue.country_id));
                        const [dataes] = listValue.country_id && statess.filter((val) => parseInt(val.id) == parseInt(listValue.country_id))
                        listValue && setstateOptionses(changeKeyObjects(dataes?.states))
                        const data = listValue.state_id && dataes?.states.filter((val) => parseInt(val.id) == parseInt(listValue.state_id))
                        const Selstates = changeKeyObjects(data);
                        const [{ states }] = listValue.country_id && citiese.filter((val) => parseInt(val.id) == parseInt(listValue.country_id))
                        const [{ cities }] = listValue.state_id && states.filter((val) => parseInt(val.id) == parseInt(listValue.state_id))
                        const citye = listValue.city_id && cities.filter((val) => parseInt(val.id) == parseInt(listValue.city_id))
                        const citiesa = changeKeyObjects(citye)
                        listValue && setcitiesOptionses(changeKeyObjects(cities))
                        const filteredArray = categories && listValue.cat_ids.map((vaata) => {
                            return categories.filter((obj1) => parseInt(obj1.value) === parseInt(vaata))
                        })
                        const listlavel = options && options.filter((obj1) => parseInt(obj1.value) === parseInt(listValue.list_level))

                        datas.push({
                            country: selectedCountry.pop(),
                            state: Selstates.pop(),
                            city: citiesa.pop(),
                            id: listValue.id,
                            category: filteredArray.map((vale) => vale.pop()),
                            listlevel: listlavel && listlavel.pop(),
                            cityimage: JSON.parse(listValue?.json_data).city.image && JSON.parse(listValue?.json_data).city.image
                        })

                    }
                }

                setInputList(datas)
            }

        }
    }, [Rowdata.id, categories?.length, listingdetails?.length])



    const handleAddSelectionFields = (index, values) => {
        setInputList([
            ...inputList,
            {
                id: "",
                country: "",
                state: "",
                city: "",
                category: "",
                listlevel: "",
                cityimage: "",
            },
        ])

    }




    const HandleRemove = async (id, index) => {
        const list = [...inputList]
        list.splice(index, 1)

        const data = {
            id: id
        }
        await axios.delete(`${process.env.REACT_APP_BaseURL}/api/actions/remove`, { data }).then((val) => {
            toast.success("successfully Remove")
        });

        setInputList(list)
    }

    const onChange = (e, index, name) => {
        const updatedLocations = [...inputList];
        updatedLocations[index].country = e;

        // clearErrors,setError
        if (e.value) {
            clearErrors("country")
        }

        updatedLocations[index].state = ''; // Reset state when country changes
        updatedLocations[index].city = '';  // Reset city when country changes

        // setError("state", {message: "state is Requried!"})

        setInputList(updatedLocations);
        const [{ states }] = statess.filter((val) => parseInt(val.id) == parseInt(e.value))
        setstateOptionses(changeKeyObjects(states))
    }
    const onChangeState = (index, e, countryid) => {
        if (countryid) {
            const [{ states }] = countryid?.value ? citiese.filter((val) => val.id == parseInt(countryid.value)) : citiese.filter((val) => val.id == parseInt(countryid.value))
            const [{ cities }] = states && states.filter((val) => val.id == e.value)
            cities && setcitiesOptionses(changeKeyObjects(cities))
            const updatedLocations = [...inputList];
            updatedLocations[index].country = countryid;
            updatedLocations[index].state = e;
            if (e.value) {
                clearErrors("state")
            }
            updatedLocations[index].city = ''; // Reset state when country changes
            setInputList(updatedLocations);
        }
    }
    const onChangeCity = async (event, index, oneValue) => {

        const updatedLocations = [...inputList];
        updatedLocations[index].city = event; // Reset state when country changes
        setInputList(updatedLocations);
        if (event.value) {
            clearErrors("city")
        }



    }
    const HandleOnChange = (event, index, name, values) => {
        const updatedLocations = [...inputList];

        updatedLocations[index][name] = event;
        setInputList(updatedLocations);

    }

    useEffect(() => {
        if (inputList.length) {
            setValue("location", inputList)
        }
    }, [inputList.length])


    return (
        <>
            <div className="col-md-12">
                <h5 className="mb-3 text-lg">Select Countries</h5>
            </div>
            {!loader ? <div className='last-child'>
                {inputList.map((values, index) => <div key={index} className={`row my_row`}>
                    <div className={`col-lg-2 col-sm-6 selection_felid ${errors.country ? "error" : ""}`}>
                        <label>
                            {errors.country ? (
                                <>
                                    <i
                                        className="fa fa-exclamation-triangle"
                                        aria-hidden="true"
                                    ></i>
                                    Country is Required
                                </>
                            ) : (
                                <>

                                    Country <span className='text-red-500'>*</span>
                                </>
                            )}

                        </label>
                        <Select
                            // isClearable
                            options={countriesOptions}
                            // onChange={onChange}
                            onChange={(e) => onChange(e, index, `country`)}
                            name="country"
                            // value={CountiresOptionse}
                            value={values.country}
                            styles={customStyles}
                        />
                    </div>
                    {values.country?.value && <div className={`col-lg-2 col-sm-6 selection_felid ${errors.state ? "error" : ""}`}>
                        <label>
                            {errors.state ? (
                                <>
                                    <i
                                        className="fa fa-exclamation-triangle"
                                        aria-hidden="true"
                                    ></i>
                                    States is Required
                                </>
                            ) : (
                                <>
                                    States <span className='text-red-500'>*</span>
                                </>
                            )}
                        </label>
                        <Select
                            // isClearable
                            options={stateOptionses}
                            // onChange={onChangeState}
                            onChange={(event) => onChangeState(index, event, values.country)}
                            name={"state"}
                            // value={StatesOption}
                            value={values.state}
                            styles={customStyles}
                        />
                    </div>}
                    {values.state?.value && <div className={`col-lg-2 col-sm-6 selection_felid ${errors.city ? "error" : ""}`}>
                        <label>
                            {errors.city ? (
                                <>
                                    <i
                                        className="fa fa-exclamation-triangle"
                                        aria-hidden="true"
                                    ></i>
                                    City is Required
                                </>
                            ) : (
                                <>
                                    City <span className='text-red-500'>*</span>
                                </>
                            )}
                        </label>
                        <Select
                            // isClearable
                            // isMulti
                            options={citiesOptionses}
                            // onChange={onChangeCity}
                            onChange={(event) => onChangeCity(event, index, [values.country, values.state])}
                            name={"city"}
                            // value={CityOption}
                            value={values.city}
                            styles={customStyles}
                        />
                    </div>

                    }
                    {/* {values.city && <div className={`cityimage w-40`}>
                    <Binary
                        Rowdata={Rowdata}
                        name={'cityimage'}
                        index={index}
                        image={values.cityimage}
                        clearErrors={clearErrors}
                        setInputList={setInputList}
                        inputList={inputList}
                        setValue={setValue}
                        errors={errors.cityimage}
                    />
                    </div>} */}
                    
                    {<div className={`col-lg-2 selection_felid ${errors.location && !values.category.value ? "error" : ""
                        }`}>

                        <label>
                            {errors.location && !values.category.value ? (
                                <>
                                    <i
                                        className="fa fa-exclamation-triangle"
                                        aria-hidden="true"
                                    ></i>
                                    Category is Required!
                                </>
                            ) : (
                                <>
                                    Select Categories <span className="text-red-500">*</span> {!categories ? <i className="fa fa-spin fa-refresh"></i> : null}
                                </>
                            )}
                        </label>
                        <div className="relative text_felid">

                            <Select
                                isClearable
                                options={categories}
                                onChange={(event) => HandleOnChange(event, index, "category", [values.city])}
                                value={values.category}
                                styles={customStyles}
                                isMulti
                            />

                        </div>
                    </div>}
                    {<div className={`col-lg-2 text_felid ${errors.location && !values.listlevel.value ? "error" : ""
                        }`}>
                        <label>List Level <span className="text-red-500">*</span></label>
                        <div className="position-relative text_felid">
                            <Select
                                // isMulti
                                isClearable
                                options={options}
                                onChange={(event) => HandleOnChange(event, index, "listlevel", [values.city])}
                                value={values.listlevel}
                                styles={customStyles}
                            />
                        </div>
                    </div>}
                    <div className={`col-lg-2 col-sm-6 relative top-0.5`}>
                        <label className='block'>&nbsp;</label>
                        <button type="button" onClick={() => handleAddSelectionFields([values], index)} className={`${index != 0 ? "mr-2" : ""} py-1.5 text-white px-2.5 bg-gray-700 rounded`}><i className='fa fa-plus'></i></button>
                        {index != 0 ? <button type="button" onClick={() => HandleRemove(values.id, index)} className='py-1.5 text-white px-2.5 bg-red-600 rounded'><i className='fa fa-minus'></i></button> : null}
                    </div>
                </div>)}
            </div> :

                <div className='text-center mb-4'>
                    <i className='fa fa-spin fa-spinner text-4xl site-text'></i>
                </div>
            }
        </>
    )
}

export default LocSelection


export const customStyles = {
    option: (base, state) => ({
        ...base,
        color: state.isSelected ? "#444" : "#444",
        backgroundColor: state.isSelected ? "#fff" : "#fff",
        padding: "8px 10px",
        textTransform: "capitalize",
        margin: 0,
        cursor: "pointer",
        textAlign: "left",
        borderRadius: "0",
        "&:hover": {
            background: "#179506",
            color: "#fff",
        },
        "&:focus": {
            background: "#179506",
            color: "#fff",
        },
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 600ms";

        return { ...provided, opacity, transition };
    },

    dropdownIndicator: (styles, state) => ({
        ...styles,
        padding: "0px 6px 0px 6px",
        color: "#999",
        backgroundColor: 0,
        textTransform: "capitalize",
        // height: '100%',
        borderRadius: "0",
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: "15px",
        textAlign: "left",
        "&:hover": {
            borderColor: state.isFocused ? "#ccc" : "#ccc",
        },
        "&:focus": {
            borderColor: state.isFocused ? "#f1f1f1" : "#f1f1f1",
        },
        color: state.isSelected ? "#000" : "#000",
        borderRadius: "4px",
        padding: 0,
        textTransform: "capitalize",
        background: "#fff",
        // backgroundColor: state.isSelected ? '#fff' : "red",
        borderColor: state.isFocused ? "#ccc" : "#ccc",
        boxShadow: "none",
        input: {
            height: "100%",
            textTransform: "capitalize",
        },
    }),
};