import axios from 'axios';
import React, { useEffect, useState } from 'react';

import CreatableSelect from 'react-select/creatable';

export default function Createable({ setValue, error, name, value,options,setOptions,selected,setSelected }) {
    const OnChangeHandler = (newValue) => {
        setSelected(newValue)
    }

    return (
        <CreatableSelect
            isClearable
            //   isDisabled={isLoading}
            //   isLoading={isLoading}
            onChange={OnChangeHandler}
            //   onCreateOption={handleCreate}
            options={options}
            value={selected}
            isMulti
        />
    );
}







