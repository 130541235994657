import * as yup from "yup";
export const schemas = yup.object().shape({
  ////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  name: yup.string().required("Category Name is Required!"),
  // description: yup.string().required("Description is Required!"),
  // banner_title: yup.string().required("Banner title is Required!"),
  // banner_dec: yup.string().required("Banner description is Required!"),
  // slug: yup.string().required("Slug is Required!"),
  // image: yup.mixed().required("Required"),
  // mp_image: yup.mixed().required("Required"),
  // icon: yup.mixed().required("Required"),
  // mp_title: yup.string().required("MP title is Required!"),
  // mp_dec: yup.string().required("MP description is Required!"),
  // content: yup.string().required("Page Content is Required!"),
  meta_title: yup.string().required("Meta title is Required!"),
  meta_keywords: yup.array().min(1, "Add min 1 keywords!").max(5, "Add Max 5 keywords!").required("Meta Keywords is Required!"),
  meta_dec: yup.string().required("Meta Description is Required!"),
});
