import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemas } from "../widgets/cateschema";
import axios from "axios";
import Editor from "./editor";
import Addkeys from "../widgets/tags";
import { Binary } from "./binary";

function CateForm({ setshowOverlay, Rowdata, setRefresh }) {
  const copyCode = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text Copy Successfully");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const [loader, setLoader] = useState(false)
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemas),
    mode: "onChange",
  });

  const values = getValues();
  const watchs = watch()
  // const { prev, next, jump, total, current, progress } = useSteps();

  // const [user, setUser] = useState(
  //   {
  //    fname: "", lname: "", email: "" 
  //   }
  // );
  const [keywords, setTags] = useState([]);

  useEffect(() => {
    if (keywords.length) {
      setValue("meta_keywords", keywords);
    }
  }, [keywords.length]);

  useEffect(() => {
    if (values.name) {
      setValue("slug", values.name.replaceAll(" ", "-").toLowerCase())
    }
  }, [values.name])

  const onSubmit = async (data) => {
    setLoader(true)
    const SendingDAta = {
      id: Rowdata.id ? Rowdata.id : "",
      banner_dec: data.banner_dec,
      banner_title: data.banner_title,
      content: data.content,
      description: "no data",
      name: data.name,
      slug: data.slug.replaceAll(" ", "-").toLowerCase(),
      meta_title: data.meta_title,
      meta_dec: data.meta_dec,
      meta_keywords: JSON.stringify(data.meta_keywords),
      image: data.image ? data.image : Rowdata.image,
      icon: data.icon ? data.icon : Rowdata.icon,
      mp_dec: data.mp_dec,
      mp_page_dec: data.mp_page_dec,
      mp_title: data.mp_title,
      mp_image: data.mp_image ? data.mp_image : Rowdata.mp_image,
      mp_meta_title: data.mp_meta_title,
      mp_meta_dec: JSON.stringify(data.mp_meta_dec),
      mp_meta_keywords: data.mp_meta_keywords
    }
    const header_options = {
      method: Rowdata.id ? "PUT" : "POST",
      mode: "cors",
      url: `${process.env.REACT_APP_BaseURL}/api/categories`,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: SendingDAta,
    };

    await axios.request(header_options).then(function (responce) {
      if (responce?.status === 201) {
        if (Rowdata.icon) {
          toast.success("Category Updated sucessfully!")
          setLoader(false)
        } else {
          toast.success("Category created sucessfully!")
          setLoader(false)
        }
        setRefresh(responce)
        setshowOverlay(false)
        reset({});
        setLoader(false)

      }
    }).catch(function (error) {
      toast.error(error?.response?.data);
      setLoader(false)
    });
  };



  useEffect(() => {

    if (Rowdata && Rowdata) {
      const { banner_dec, mp_page_dec, mp_meta_title,mp_meta_dec,mp_meta_keywords, banner_title, icon, id, image, name, slug, status, meta_title, meta_dec, meta_keywords, mp_dec, mp_title, mp_image } = Rowdata;

      setValue("banner_dec", banner_dec)
      setValue("banner_title", banner_title)
      setValue("content", Rowdata?.content)
      // setValue("description", Rowdata.description)
      setValue("icon", icon)
      setValue("id", id)
      setValue("image", image)
      setValue("name", name)
      setValue("slug", slug)
      setValue("status", status)

      setValue("meta_title", meta_title)
      setValue("meta_dec", meta_dec)

      setValue("mp_page_dec", mp_page_dec)
      setValue("mp_dec", mp_dec)
      setValue("mp_title", mp_title)
      setValue("mp_image", mp_image)



      setValue("mp_meta_title", mp_meta_title)
      setValue("mp_meta_dec", mp_meta_dec)
      setValue("mp_meta_keywords", mp_meta_keywords)

      // setValue("meta_keywords", meta_keywords && JSON.parse(meta_keywords))

      // setTags(meta_keywords && JSON.parse(meta_keywords))
      // console.log(content, description, "here is content")
    }
    if (Rowdata.id) {
      setTags(JSON.parse(Rowdata?.meta_keywords).length && JSON.parse(Rowdata?.meta_keywords))
    } else {
      setTags([])
    }
    // eslint-disable-next-line
  }, [Rowdata.id])



  return (
    <>
      <div className="py-0.5">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <h4>Catergory information</h4>
                <p>Please fill the all compulsory fields</p>
                <hr className="my-4 " />
              </div>
              <div className="text-end mb-4">
                <span className="cursor-pointer text-sm" onClick={() => copyCode(`{{category}}`)}>{`{{category}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{state}}`)}>{`{{state}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{city}}`)}>{`{{city}}`}</span>
              </div>

              <div className={`col-md-3 ${errors.icon ? "Image-error" : ""}`}>
                <Binary clearErrors={clearErrors} image={Rowdata.icon} name='icon' setValue={setValue} errors={errors.icon} Rowdata={Rowdata} />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className={`col-md-6 text_felid ${errors.name ? "error" : ""}`}>
                    <label>
                      {errors.name ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.name?.message}
                        </>
                      ) : (
                        <>
                          Catergory Name <span className="text-red-500">*</span>:
                        </>
                      )}
                    </label>
                    <div className="relative text_felid">
                      <input
                        type="text"
                        {...register("name", { required: true })}
                        placeholder="Catergory Name"
                      />
                    </div>
                  </div>
                  <div className={`col-md-6 text_felid ${errors.slug ? "error" : ""}`}>
                    <label>
                      {errors.slug ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.slug?.message}
                        </>
                      ) : (
                        <>
                          Slug
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="relative text_felid">
                      <input
                        type="text"
                        {...register("slug", { required: true })}
                        placeholder="Slug"
                      />
                    </div>
                  </div>
                  <div className={`col-md-12 text_felid ${errors.content ? "error" : ""}`}>
                    <label className="mt-1">
                      {errors.content ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.content?.message}
                        </>
                      ) : (
                        <>
                          Page Content
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="position-relative text_felid">
                      <Editor name={'content'} setValue={setValue} watch={watchs} Rowdata={Rowdata} state={values.content} error={errors?.content} clearErrors={clearErrors} />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="col-md-12">

                <div className="flex justify-between mb-3">
                  <h5 className="text-lg">SEO Fields</h5>
                  <div>
                    <span className="cursor-pointer text-sm" onClick={() => copyCode(`{{category}}`)}>{`{{category}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{state}}`)}>{`{{state}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{city}}`)}>{`{{city}}`}</span>
                  </div>
                </div>
              </div>
              <div className={`col-md-6 text_felid ${errors.meta_title ? "error" : ""}`}>
                <label>
                  {errors.meta_title ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.meta_title?.message}
                    </>
                  ) : (
                    <>
                      Meta Title <span className="text-red-500">*</span>
                    </>
                  )}
                </label>
                <div className="relative text_felid">
                  <input
                    type="text"
                    {...register("meta_title", { required: true })}
                    placeholder="Meta Title"
                  />
                </div>
              </div>
              <div className={`col-md-6 text_felid ${errors.meta_keywords ? "error" : ""}`}>
                <label>
                  {errors.meta_keywords ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.meta_keywords?.message}
                    </>
                  ) : (
                    <>
                      Meta Keywords <span className="text-red-500">*</span>
                    </>
                  )}
                </label>

                <Addkeys
                  tags={keywords}
                  labelId="meta_keywords"
                  setTags={setTags}
                  setValue={setValue}
                  name={'meta_keywords'}
                  placeholder="Meta Keywords"
                />

              </div>
              <div className={`col-md-12 text_felid ${errors.meta_dec ? "error" : ""}`}>
                <label className="mt-1">
                  {errors.meta_dec ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.meta_dec?.message}
                    </>
                  ) : (
                    <>
                      Meta Description <span className="text-red-500">*</span>
                    </>
                  )}
                </label>
                <div className="position-relative text_felid">
                  <input
                    type="text"
                    {...register("meta_dec", { required: true })}
                    placeholder="Meta Description"
                  />
                </div>
              </div>
              <hr />
              <div className="col-md-12">

                <div className="flex justify-between mb-3">
                  <h5 className="text-lg">Banner Content</h5>
                  <div>
                    <span className="cursor-pointer text-sm" onClick={() => copyCode(`{{category}}`)}>{`{{category}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{state}}`)}>{`{{state}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{city}}`)}>{`{{city}}`}</span>
                  </div>
                </div>
              </div>
              <div className={`col-md-3 ${errors.image ? "Image-error" : ""}`}>

                <Binary clearErrors={clearErrors} image={Rowdata.image} name='image' setValue={setValue} errors={errors.image} Rowdata={Rowdata} />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className={`col-md-12 relative text_felid ${errors.banner_title ? "error" : ""}`}>
                    <label>
                      {errors.banner_title ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.banner_title?.message}
                        </>
                      ) : (
                        <>
                          Banner Title
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="relative text_felid">
                      <input
                        type="text"
                        {...register("banner_title", { required: true })}
                        placeholder="Banner Title"
                      />
                    </div>
                  </div>


                  <div className={`col-md-12 text_felid ${errors.banner_dec ? "error" : ""}`}>
                    <label className="mt-1">
                      {errors.banner_dec ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.banner_dec?.message}
                        </>
                      ) : (
                        <>
                          Banner Description
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="position-relative text_felid">
                      <Editor setValue={setValue} name={'banner_dec'} state={values.banner_dec} error={errors?.banner_dec} clearErrors={clearErrors} />
                    </div>
                  </div>

                </div>
              </div>
              <hr />
              <div className="col-md-12">
                <div className="flex justify-between mb-3">
                  <h5 className="text-lg">Mass Page Content</h5>
                  <div>
                    <span className="cursor-pointer text-sm" onClick={() => copyCode(`{{category}}`)}>{`{{category}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{numbers}}`)}>{`{{numbers}}`}</span> <span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{totalnumbers}}`)}>{`{{totalnumbers}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{state}}`)}>{`{{state}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{city}}`)}>{`{{city}}`}</span>
                  </div>
                </div>



              </div>
              <div className={`col-md-3 ${errors.mp_image ? "Image-error" : ""}`}>

                <Binary clearErrors={clearErrors} image={Rowdata.mp_image} name='mp_image' setValue={setValue} errors={errors.mp_image} Rowdata={Rowdata} />
              </div>
              <div className="col-md-9">

                <div className="row">

                  <div className={`col-md-6 relative text_felid ${errors.mp_meta_title ? "error" : ""}`}>
                    <label>
                      {errors.mp_meta_title ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.mp_meta_title?.message}
                        </>
                      ) : (
                        <>
                          MP meta title
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="relative text_felid">
                      <input
                        type="text"
                        {...register("mp_meta_title", { required: true })}
                        placeholder="MP meta title"
                      />
                    </div>
                  </div>

                  <div className={`col-md-6 relative text_felid ${errors.mp_meta_dec ? "error" : ""}`}>
                    <label>
                      {errors.mp_meta_dec ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.mp_meta_dec?.message}
                        </>
                      ) : (
                        <>
                          MP Meta Description
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="relative text_felid">
                      <input
                        type="text"
                        {...register("mp_meta_dec", { required: true })}
                        placeholder="MP Title"
                      />
                    </div>
                  </div>

                  <div className={`col-md-12 relative text_felid ${errors.mp_meta_keywords ? "error" : ""}`}>
                    <label>
                      {errors.mp_meta_keywords ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.mp_meta_keywords?.message}
                        </>
                      ) : (
                        <>
                          MP Meta keywords
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="relative text_felid">
                      <input
                        type="text"
                        {...register("mp_meta_keywords", { required: true })}
                        placeholder="MP Title"
                      />
                    </div>
                  </div>

                  <div className={`col-md-6 relative text_felid ${errors.mp_title ? "error" : ""}`}>
                    <label>
                      {errors.mp_title ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.mp_title?.message}
                        </>
                      ) : (
                        <>
                          MP Banner Title
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="relative text_felid">
                      <input
                        type="text"
                        {...register("mp_title", { required: true })}
                        placeholder="MP Banner Title"
                      />
                    </div>
                  </div>

                  <div className={`col-md-6 relative text_felid ${errors.mp_dec ? "error" : ""}`}>
                    <label>
                      {errors.mp_dec ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.mp_dec?.message}
                        </>
                      ) : (
                        <>
                          MP Banner Description
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="relative text_felid">
                      <input
                        type="text"
                        {...register("mp_dec", { required: true })}
                        placeholder="MP Banner Description "
                      />
                    </div>
                  </div>

                  <div className={`col-md-12 text_felid ${errors.mp_dec ? "error" : ""}`}>
                    <label className="mt-1">
                      {errors.mp_dec ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.mp_dec?.message}
                        </>
                      ) : (
                        <>
                          MP Page Content 
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="position-relative text_felid">
                      <Editor setValue={setValue} name={'mp_page_dec'} state={values?.mp_page_dec} error={errors?.mp_page_dec} clearErrors={clearErrors} />
                    </div>
                  </div>

                </div>

              </div>

              <div className="col-md-12 mb-4">
                <button type="submit" disabled={loader ? true : false} className="bg-green-500  px-5 py-2 border-0 rounded-md text-white">{loader ? <><i className="fa fa-spinner fa-spin fa-2x fa-fw"></i></> : "Submit"}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default CateForm;
