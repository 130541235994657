import React from 'react'

export default function SeoData() {
  return (
    <>
        <div className="p-4 grid grid-cols-12">
            <div className="col-span-12 mb-3">
                <h3>Add Meta Tags</h3>
            </div>
            <div className="col-span-12">
                <form className='bg-white p-3 rounded-md'>
                    <div className="row">
                        <div className="col-md-6">
                            <div className='mb-3'>
                                <label>Meta Title</label>
                                <input type="text" className='form-control' placeholder='Meta Title'/>
                            </div>
                            
                        </div>
                        <div className="col-md-6">
                        <div className='mb-3'>
                                <label>Meta Keywords</label>
                                <input type="text" className='form-control' placeholder='Meta Keywords'/>
                            </div>
                        </div>
                    </div>
                <div className='mb-3'>
                    <label>Meta Discription</label>
                    <textarea className='min-h-[150px!important] form-control'></textarea>
                </div>
                <div>
                    <button className='btn-normal btn-green min-w-[8%] py-[10px] text-white'>Submit</button>
                </div>
                </form>
            </div>
        </div>
    </>
  )
}
