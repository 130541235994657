import * as yup from "yup";


export const schemas = yup.object().shape({
  ////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  title: yup.string().required("Title is Required!"),
  // short_dec: yup.string().required("Short decription is Required!"),
  // leader_name: yup.string().required("Leader's Name is Required!"),
  // leader_designation: yup.string().required("Leader's Designation is Required!"),
  // leader_msg: yup.string().required("Leader Description is Required!"),
  // com_heading: yup.string().required("Custom heading is Required!"),
  meta_title: yup.string().required("Meta Title is Required!"),
  meta_keywords: yup.array().min(1, "Add Min one Keywords").max(5, "Add Max 5 Keywords").required("Meta Keywords is Required!"),
  // allow_email: yup.string().required("This field is Required!"),
  meta_dec: yup.string().required("Meta Description is Required!"),
  // address: yup.string().required("Company Address is Required!"),
  // url: yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "Enter correct url!").required("URL is Required!"),
  // rating_skils: yup.string().required("Rating is Required!"),
  // category: yup.object().shape({
  //   label: yup.string().required(),
  //   value: yup.string().required(),
  // }).required("Category is Required!"),
  // location: yup.array().min(1, "Add Min one location").required('Country is required'),
  // web_email: yup.string().email("Email Not Valid").required("Email is Required!"),
  // web_phone: yup.number().required("Website Phone is Required!"),
  // leader_phone: yup.number().required("Phone no# is Required!"),
  // leader_email: yup.string().email("Email Not Valid").required("Email is Required!"),
  // logo: yup.mixed().required("Required")
  //   country: yup.object().shape({
  //     label: yup.string().required(),
  //     value: yup.string().required(),
  //   }).required("Country is Required!"), 
  //   state: yup.object().shape({
  //     label: yup.string().required(),
  //     value: yup.string().required(),
  //   }).required("State is Required!"),
  //   city: yup.array().min(1, "Add Min 1 City!").max(10, "Add Max 5 City!").of(yup.object().shape({
  //     label: yup.string().nullable().required(),
  //     value: yup.string().nullable().required(),
  //   })
  // ).required("City is Required!"),
  // name: yup.string().required("Name is Required!"),
  // email: yup.string().email("Email Not Valid").required("Email is Required!"),
  // phone: yup.number().required("Phone number is Required!"),
  
});
