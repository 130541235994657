import { useState } from "react";
import { useMediaQuery } from "react-responsive";
function Addkeys(props) {

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 991 });

  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const isRetina = useMediaQuery({ minResolution: "2dppx" });
  
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [input, setInput] = useState("");
  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    if(isTabletOrMobile){
      console.log("")
    }else if (
      key === "," ||
      (key === "Enter" && trimmedInput.length && !props.tags.includes(trimmedInput))
    ) {
      e.preventDefault();
      props.setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
      props.setValue(props.name, (prevState) => [...prevState, trimmedInput])
    }

    if (key === "Backspace" && !input.length && props.tags.length && isKeyReleased) {
      const tagsCopy = [...props.tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      props.setTags(tagsCopy);
      props.setValue(props.name, tagsCopy)
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyDownForMobile = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (trimmedInput.length && !props.tags.includes(trimmedInput)) {
      e.preventDefault();
      props.setTags((prevState) => [...prevState, trimmedInput]);
      props.setValue(props.name, (prevState) => [...prevState, trimmedInput])
      setInput("");
    }

    if (key === "Backspace" && !input.length && props.tags.length && isKeyReleased) {
      const tagsCopy = [...props.tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      props.setTags(tagsCopy);
      props.setValue(props.name, tagsCopy)
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };
  const deleteTag = (index) => {
    props.setTags((prevState) => prevState.filter((tag, i) => i !== index));
    props.setValue(props.name, (prevState) => prevState.filter((tag, i) => i !== index))
  };

  return (
    <div className={`text_felid position-relative`}>
      <input
        value={input}
        placeholder={props.placeholder}
        onKeyDown={!isTabletOrMobile ? onKeyDown : () => console.log("")}
        id={props.labelId}
        name={props.name}
        onKeyUp={onKeyUp}
        onChange={onChange}
        type="text"
      />
      {isTabletOrMobile && <button type="button" className="absolute top-[3px] h-[34px] right-1 w-auto text-sm leading-tight bg-gray-700 text-white rounded-md"  onClick={onKeyDownForMobile}>Add tags</button>}
      <div className="tag_list">

      {props?.tags?.length && props.tags.map((tag, index) => (
        <div className="tag" key={index}>
          <span>{tag}</span>
          <small onClick={() => deleteTag(index)}><i className="fa fa-times"></i></small>
        </div>
      ))}
      </div>
    </div>
  );
}

export default Addkeys;
