import * as yup from "yup";
export const schemas = yup.object().shape({
  ////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  name: yup.string().required("Name is Required!"),
  service: yup.mixed().required("Required"),
  description: yup.string().required("Description is Required!"),
  // url: yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "Enter correct url!").required("URL is Required!"),

});
