import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from 'react-select'
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import axios from "axios";
export default function SortData() {

  const [Categories, setCategories] = useState([]);


  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    control,
    resetField,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schemas),
    mode: "onChange",
  });

  const values = getValues();

  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? "#444" : "#444",
      backgroundColor: state.isSelected ? "#fff" : "#fff",
      padding: "8px 10px",
      textTransform: "capitalize",
      margin: 0,
      cursor: "pointer",
      textAlign: "left",
      borderRadius: "0",
      "&:hover": {
        background: "#179506",
        color: "#fff",
      },
      "&:focus": {
        background: "#179506",
        color: "#fff",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 600ms";

      return { ...provided, opacity, transition };
    },

    dropdownIndicator: (styles, state) => ({
      ...styles,
      padding: "0px 6px 0px 6px",
      color: "#999",
      backgroundColor: 0,
      textTransform: "capitalize",
      // height: '100%',
      borderRadius: "0",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: "15px",
      textAlign: "left",
      "&:hover": {
        borderColor: state.isFocused ? "#ccc" : "#ccc",
      },
      "&:focus": {
        borderColor: state.isFocused ? "#f1f1f1" : "#f1f1f1",
      },
      color: state.isSelected ? "#000" : "#000",
      borderRadius: "4px",
      padding: 0,
      textTransform: "capitalize",
      background: "#fff",
      // backgroundColor: state.isSelected ? '#fff' : "red",
      borderColor: state.isFocused ? "#ccc" : "#ccc",
      boxShadow: "none",
      input: {
        height: "100%",
        textTransform: "capitalize",
      },
    }),
  };
  const [rawdata, setrawDate] = useState([]);

  const NumBers = () => {
    const array = 100
    const data = []
    for (let i = 1; i <= array; i++) {
      data.push(i)
    }
    return data
  }
  const listoflavel = [
    {
        label: "free",
        value: "1",
    },
    {
        label: "sponsored",
        value: "2",
    },
    {
        label: "headline",
        value: "3",
    },
]
  const columnDefs = [
    {
      field: "sort_key",
      headerName: "id",
      filter: "agNumberColumnFilter",
      suppressMenu: true,
      minWidth: 100,
      suppressSizeToFit: true,
      cellRenderer: (params) => ChangeHandle(params),
      // cellEditor: 'agSelectCellEditor',
      // cellEditorParams: {
      //   values: NumBers(),
      // },
      maxWidth: 120,
    },
    {
      field: "com_heading",
      headerName: "Title",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      // minWidth: 300,
      // maxWidth: 300,
      suppressSizeToFit: true,
    },
    {
      field: "logo",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      minWidth: 200,
      maxWidth: 200,
      suppressSizeToFit: true,
      cellRenderer: (params) => process.env.NODE_ENV === "development" ? <img className="w-14" src={`${process.env.REACT_APP_BaseURL}/extraimages/listing/${params.value}`} alt="img here" /> : <img src={`${process.env.REACT_APP_BaseURL}/public/extraimages/listing/${params.value}`} alt="logo here" />
    },
    {
      field: "website_url",
      headerName: "URL",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      minWidth: 250,
      maxWidth: 250,
      suppressSizeToFit: true,
    },
    {
      field: "list_level",
      headerName: "list level",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      minWidth: 250,
      maxWidth: 250,
      suppressSizeToFit: true,
      cellRenderer: (params) => {
        let [obj] = listoflavel.filter((val) => parseInt(params.value) === parseInt(val.value))
        return obj.label.charAt(0).toUpperCase() + obj.label.slice(1).replaceAll("-", " ")
      } 
    },
    
    // {
    //   field: "status",
    //   filter: "agTextColumnFilter",
    //   suppressMenu: true,
    //   minWidth: 120,
    //   maxWidth: 120,
    //   suppressSizeToFit: true,
    //   cellRenderer: (params) => <span>{parseInt(params.value) === 0 ? "New" : parseInt(params.value) === 1 ? "Active" : parseInt(params.value) === 201 && "Active"}</span>
    // },

  ];
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
      width: 100,
      minWidth: 100,
    };
  }, []);

  function getUnique(array, key) {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map())
        .values()
    );
  }

  const CategoryOptions = (obj) => {
    // debugger
    let res = [];
    for (let i = 0; i < obj.length; i++) {
      let objs = {
        value: obj[i].id,
        label: obj[i].slug.charAt(0).toUpperCase() + obj[i].slug.slice(1).replaceAll("-", " "),
        icon: obj[i].icon,
      };
      res.push(objs);
    }
    return res;
  };
  const CitiesOptions = (obj) => {
    // debugger
    let res = [];
    for (let i = 0; i < obj.length; i++) {
      let objs = {
        value: obj[i].city_id,
        label: obj[i].name.charAt(0).toUpperCase() + obj[i].name.slice(1).replaceAll("-", " "),
        city_slug: obj[i].city_slug,
      };
      res.push(objs);
    }
    return res;
  };
  const getApiCate = async () => {
    const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/actions/sorting/categories`);
    const res = await api.json();

    const AllCategories = res.length && CategoryOptions(getUnique(res, "id"))
    const AllCities = res.length && CitiesOptions(getUnique(res, "city_id"))

    setCategories({
      AllCategories,
      AllCities
    })
  }
  useEffect(() => {
    getApiCate()
  }, [])



  const onSubmit = async (data) => {

    const { category, city } = data

    const FormDatas = {
      cat_id: category?.value,
      city_id: city.value
    };
    const header_options = {
      method: "POST",
      mode: "cors",
      url: `${process.env.REACT_APP_BaseURL}/api/actions/sorting/listing`,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: FormDatas,
    };

    axios.request(header_options).then(function (responce) {
      if (responce?.status === 200) {
        toast.success(`${responce?.data.length} Record Found`);
        setrawDate(responce?.data)
      }


    }).catch(function (error) {
      toast.error(error?.response?.data);
    });
  }



  const ChangeHandle = (params) => {


    const HandleClick = async (e) => {
      const FormDatas = {
        id: params?.data?.id2,
        key: e.target.value
      };

      const header_options = {
        method: "PUT",
        mode: "cors",
        url: `${process.env.REACT_APP_BaseURL}/api/actions/sorting/listing`,
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: FormDatas,
      };

      await axios.request(header_options).then(function (responce) {
        if (responce?.status === 200) {
          toast.success(`Updated Record`);
        }
      }).catch(function (error) {
        toast.error(error?.response?.data);
      });
    }


    return (
      <>
        <select onChange={HandleClick} defaultValue={params.value} className="bg-transparent">
          {NumBers().map((val, index) => <option key={index}>{val}</option>)}
        </select>
      </>
    )

  }

  console.log(rawdata, "here is row data")
  return (
    <>
      <div className="p-4 grid grid-cols-12">
        <div className="col-span-12 mb-3">
          <h3>Sort Data</h3>
        </div>
        <div className="col-span-12">
          <div className='bg-white p-3 rounded-md'>
            <form className='mb-3 z-10 relative' onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-5 flex-1">
                  <div className='mb-3'>
                    <label>Select City *</label>
                    <Controller
                      control={control}
                      name="city"
                      rules={{ required: true }}
                      render={({ field: { ...field } }) => (
                        <Select
                          {...field}
                          options={Categories.AllCities}
                          styles={customStyles}
                        />

                      )}
                    />

                  </div>

                </div>
                <div className="col-md-5 flex-1">
                  <div className='mb-3'>
                    <label>Select Category *</label>
                    <Controller
                      control={control}
                      name="category"
                      rules={{ required: true }}
                      render={({ field: { ...field } }) => (
                        <Select
                          {...field}
                          options={Categories.AllCategories}
                          styles={customStyles}
                        />

                      )}
                    />

                  </div>
                </div>
                <div className="col-md-2 flex-1">
                  <div className='Search_btn'>
                    <button className='mt-4 btn-normal btn-green px-5 py-2 text-white' type='submit'>Search Listing</button>
                  </div>
                </div>
              </div>
            </form>
            <AgGridReact
              className="ag-theme-alpine h100"
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={rawdata}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
              animateRows={true}
            />
          </div>
        </div>

      </div>
    </>
  )
}
