export const Selectedcountry = [
    {
        id: 233,
        name: "United States",
        iso3: "USA",
        iso2: "US",
        numeric_code: "840",
        phone_code: 1,
        capital: "Washington",
        currency: "USD",
        currency_name: "United States dollar",
        currency_symbol: "$",
        tld: ".us",
        native: "United States",
        region: "Americas",
        subregion: "Northern America",
        latitude: "38.00000000",
        longitude: "-97.00000000",
        emoji: "🇺🇸"
    },
    // {
    //     id: 231,
    //     name: "United Arab Emirates",
    //     iso3: "ARE",
    //     iso2: "AE",
    //     numeric_code: "784",
    //     phone_code: 971,
    //     capital: "Abu Dhabi",
    //     currency: "AED",
    //     currency_name: "United Arab Emirates dirham",
    //     currency_symbol: "إ.د",
    //     tld: ".ae",
    //     native: "دولة الإمارات العربية المتحدة",
    //     region: "Asia",
    //     subregion: "Western Asia",
    //     latitude: "24.00000000",
    //     longitude: "54.00000000",
    //     emoji: "🇦🇪"
    // },
]