import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemas } from "../widgets/listingschema";
import PhoneInput from "react-phone-input-2";
import { Binary } from "./binary";
import axios from "axios";
import Editor from "../components/editor";
import Addkeys from "../widgets/tags";
import LocSelection from "./locations/locSelection";
import { toast } from "react-toastify";
import Createable from "./Createable";
import { useMemo } from "react";


function ListingForm({ setshowOverlay, Rowdata, setRefresh }) {

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    control,
    resetField,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemas),
    mode: "onChange",
  });
  const values = getValues();
  const watchs = watch();
  console.log(values, 'input values')
  const [keywords, setTags] = useState([]);
  const [selected, setSelected] = useState();
  const [categories, setcategories] = useState([]);
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);
  const [compare, setcompare] = useState([]);
  const [countriesOption, setcountries] = useState('');
  const [StatesOption, setstatess] = useState('');
  const [CityOption, setcities] = useState('');
  const [inputList, setInputList] = useState([
    {
      country: "",
      state: "",
      city: "",
      category: "",
      listlevel: "",
      city_image: "",
    },
  ])


  const [RadioBtn, setRadio] = useState(0);
  const [checkboxes, setCheckboxes] = useState([
    {
      id: 1,
      name: "A Rated",
      checked: false,
    },
    {
      id: 2,
      name: "Guaranteed",
      checked: false,
    },
    {
      id: 3,
      name: "Insured",
      checked: true,
    },
    {
      id: 4,
      name: "Verified",
      checked: false,
    },
    {
      id: 5,
      name: "Licensed",
      checked: false,
    },
  ]);


  const [selectedValues, setSelectedValues] = useState([]);
  const [show, setShow] = useState(false);



  useEffect(() => {
    setValue("meta_keywords", keywords);
    if (keywords.length) {
      clearErrors("meta_keywords");
    }
    // eslint-disable-next-line
  }, [keywords]);
  // useEffect(() => {
  //   setValue("tags", keywords);
  //   if (keywords.length) {
  //     clearErrors("tags");
  //   }
  //   // eslint-disable-next-line
  // }, [keywords]);




  const [phoneNumber, setPhoneNumber] = useState({
    // phone: "",
    web_phone: "",
  });



  const copyCode = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text Copy Successfully");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const onSubmit = async (data) => {

    const country = inputList.map((val) => {
      return {
        label: val?.country?.label,
        code: val?.country?.data?.iso3
      }
    })
    // const categoryMAP = []
    const categoryMAP = inputList.length && inputList.map((vel) => {
      return vel?.category && vel?.category.flatMap((val) => val?.value && val?.value)
    })
    const FormDatas = {
      id: Rowdata.id ? Rowdata.id : "",
      web_email: data.web_email,
      web_phone: data.web_phone,
      title: data.title,
      url: data.url,
      list: inputList,
      meta_keywords: JSON.stringify(data.meta_keywords.map((val) => val.replaceAll(" ", "-").toLowerCase())),
      mulitags: JSON.stringify(data.mulitags) ? JSON.stringify(data.mulitags) : "",
      logo: data.logo ? data.logo : Rowdata.logo,
      custom_title: data.custom_title,
      experince: data.experince,
      membership: data.membership,
      leader_name: data.leader_name,
      leader_designation: data.leader_designation,
      meta_title: data.meta_title,
      com_heading: data.com_heading,
      short_dec: JSON.stringify(data.short_dec),
      custom_dec: JSON.stringify(data.custom_dec),
      leader_msg: JSON.stringify(data.leader_msg),
      leader_phone: data.leader_phone,
      leader_email: data.leader_email,
      meta_dec: JSON.stringify(data.meta_dec),
      r1: data.r1,
      r2: data.r2,
      r3: data.r3,
      r4: data.r4,
      r5: data.r5,
      category: JSON.stringify(categoryMAP) && JSON.stringify(categoryMAP),
      city: inputList && JSON.stringify(inputList.map((val) => val.city.label)),
      country: JSON.stringify(country),
      state: inputList && JSON.stringify(inputList.map((val) => val.state.label)),
      address: data.address,
      allow_email: data.allow_email,
      rating_skils: JSON.stringify(data.rating_skils),
      author_review: data.author_review,
      author: data.author,
    };

    const header_options = {
      method: Rowdata.id ? "PUT" : "POST",
      mode: "cors",
      url: `${process.env.REACT_APP_BaseURL}/api/listing`,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: FormDatas,
    };

    axios.request(header_options).then(function (responce) {
      if (responce?.status === 201) {
        if (Rowdata.id) {
          toast.success("Listing sucessfully Updated");
        } else {
          toast.success("Listing sucessfully created");
        }
        reset({})
        setPhoneNumber([])
        setTags([])
        setstatess([])
        setcities([])
        setshowOverlay(false)
        setRefresh(responce)
      }

    }).catch(function (error) {
      toast.error(error?.response?.data);
    });
  }

  const changeKeyObjects = (obj) => {
    // debugger
    let res = [];
    for (let i = 0; i < obj.length; i++) {
      let objs = {
        value: obj[i].id,
        label: obj[i].name,
      };
      res.push(objs);
    }
    return res;
  };

  const listingData = async () => {
    const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/categories`);
    const res = await api.json();
    const listtags = await fetch(`${process.env.REACT_APP_BaseURL}/api/tags`);
    const tags = await listtags.json();
    // setcompare(tags)

    let options = []
    for (let index = 0; index < tags.length; index++) {
      let element = tags[index];
      const final = {
        label: element.label.charAt(0).toUpperCase() + element.label.slice(1).replaceAll("-", " "),
        value: element.value,
        id: element.id
      }
      options.push(final)
    }

    setOptions(options)
    const update = changeKeyObjects(res)
    setcategories(update);
    setLoader(false)
  }

  useMemo(() => {
    if (selected) {
      setValue("mulitags", selected)
    }
  }, [selected])

  useEffect(() => {
    setLoader(true)
    listingData();
  }, [])

  useMemo(() => {

    if (Rowdata.id) {
      const { logo, address, mulitags, author_review, author, allow_email, rating_skils, r1, r2, r3, r4, r5, leader_email, meta_dec, status, leader_designation, com_heading, short_dec, custom_dec, leader_msg, meta_title, leader_phone, id, title, user_id, web_email, web_phone, website_url, meta_keywords, custom_title, experince, membership, leader_name, country, city, state, category } = Rowdata;

      const tagslist = JSON.parse(mulitags) && JSON.parse(mulitags).map((val) => {
        return options.filter((obj1) => obj1.value.includes(val))
      })


      // const tagslist = options.length > 0 ? options.filter((obj1) => mulitags && JSON.parse(mulitags).some((obj2) => obj1.label === obj2)) : [];


      setValue("logo", logo)
      setValue("id", id)
      setValue("status", status)
      setValue("title", title)
      setValue("user_id", user_id)
      setValue("web_email", web_email)
      setValue("web_phone", web_phone)
      setValue("url", website_url)

      let listoftags = tagslist.length > 0 && tagslist.map((val) => val.pop())



      setSelected(listoftags)

      // setValue("mulitags", listoftags)

      setValue("author_review", author_review)
      setValue("author", author)


      setTags(JSON.parse(meta_keywords))

      setValue("custom_title", custom_title)
      setValue("experince", experince)

      setValue("membership", membership)
      setValue("leader_name", leader_name)
      setValue("leader_designation", leader_designation)
      setValue("meta_title", meta_title)
      setValue("com_heading", com_heading)
      setValue("short_dec", JSON.parse(short_dec))
      setValue("custom_dec", JSON.parse(custom_dec))
      setValue("leader_msg", JSON.parse(leader_msg))
      setValue("leader_phone", leader_phone)

      setValue("leader_email", leader_email)
      setValue("meta_dec", JSON.parse(meta_dec))

      setValue("r1", r1)
      setValue("r2", r2)
      setValue("r3", r3)
      setValue("r4", r4)
      setValue("r5", r5)

      setValue("country", country)
      setValue("city", city)
      setValue("state", state)
      // setValue("category", category)

      setValue("address", address)
      setValue("allow_email", allow_email)
      setRadio(allow_email)
      // setCheckbox({box:JSON.parse(rating_skils)})


      setSelectedValues(JSON.parse(rating_skils))
      // setValue("list", list)

      // setInputList(list)



      setPhoneNumber({
        ...phoneNumber,
        web_phone: web_phone,
        leader_phone: leader_phone,
      });



      // setTags(tags && JSON.parse(tags));
    }

    // eslint-disable-next-line
  }, [Rowdata.id, options.length]);








  const handleCheckboxChange = (event) => {
    if (selectedValues.includes(event.target.name)) {
      setSelectedValues(selectedValues.filter((v) => v !== event.target.name));
    } else {
      setSelectedValues([...selectedValues, event.target.name]);
    }
  };

  useEffect(() => {
    setValue("rating_skils", selectedValues)
  }, [selectedValues.length])

  const ToggleButton = (e) => {
    setShow(prev => !prev)
  }

  const OnSelect = (e) => {
    const { name, value } = e.target
    setRadio(value)
    setValue("allow_email", value)
  }




  return (
    <>
      <div className="py-0.5">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <h4>Company information</h4>
                <p>Please fill the all compulsory fields</p>
              </div>
              <hr className="my-4" />
              <LocSelection loader={loader} setValue={setValue} Rowdata={Rowdata} inputList={inputList} setInputList={setInputList} categories={categories} control={control} errors={errors} values={values} countriesOption={countriesOption} StatesOption={StatesOption} CityOption={CityOption}
                setcountries={setcountries} setstatess={setstatess} setcities={setcities}
                clearErrors={clearErrors} setError={setError} />

              <hr className="mt-0" />
              <div className={`col-md-3 ${errors.logo ? "Image-error" : ""}`}>
                <Binary
                  image={Rowdata.logo}
                  Rowdata={Rowdata}
                  name={'logo'}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  errors={errors.logo}
                />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div
                    className={`col-md-6 text_felid ${errors.title ? "error" : ""
                      }`}
                  >
                    <div className="flex justify-between">
                      <label>
                        {errors.title ? (
                          <>
                            <i
                              className="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                            {errors?.title?.message}
                          </>
                        ) : (
                          <>
                            {/* Website title */}
                            Company Name <span className="text-red-500">*</span>
                          </>
                        )}
                      </label>
                      <div>
                        <span className="cursor-pointer text-sm" onClick={() => copyCode(`{{category}}`)}>{`{{category}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{state}}`)}>{`{{state}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{city}}`)}>{`{{city}}`}</span>
                      </div>
                    </div>

                    <div className="position-relative text_felid">
                      <input
                        type="text"
                        {...register("title", { required: true })}
                        placeholder="Company Name"
                      />
                    </div>
                  </div>
                  <div
                    className={`col-md-6 text_felid ${errors.com_heading ? "error" : ""
                      }`}
                  >

                    <div className="flex justify-between">
                      <label>
                        {errors.com_heading ? (
                          <>
                            <i
                              className="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                            {errors?.com_heading?.message}
                          </>
                        ) : (
                          <>
                            Company Heading

                          </>
                        )}
                      </label>
                      <div>
                        <span className="cursor-pointer text-sm" onClick={() => copyCode(`{{category}}`)}>{`{{category}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{state}}`)}>{`{{state}}`}</span><span className="px-1">-</span><span className="cursor-pointer text-sm" onClick={() => copyCode(`{{city}}`)}>{`{{city}}`}</span>
                      </div>
                    </div>
                    <div className="position-relative text_felid">
                      <input
                        type="text"
                        {...register("com_heading", { required: true })}
                        placeholder="Company Heading"
                      />
                    </div>
                  </div>
                  <div
                    className={`col-md-6 text_felid ${errors.web_email ? "error" : ""
                      }`}
                  >
                    <label>
                      {errors.web_email ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.web_email?.message}
                        </>
                      ) : (
                        <>Company Email
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="position-relative text_felid">
                      <input
                        type="text"
                        {...register("web_email", { required: true })}
                        placeholder="Company Email"
                      />
                    </div>
                  </div>

                  <div
                    className={`col-md-6 text_felid ${errors.web_phone ? "error" : ""
                      }`}
                  >

                    <label>
                      {errors.web_phone ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.web_phone?.message}
                        </>
                      ) : (
                        <>Website Phone
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    {/* <PhoneInput
                      country={"us"} // Set the default country
                      value={phoneNumber.web_phone} // Set the phone number value
                      onChange={(val) => handleOnChange(val, "web_phone")} // Handle changes to the phone number
                    /> */}
                    <Controller
                      control={control}
                      name="web_phone"
                      rules={{ required: true }}
                      render={({ field: { ref, ...field } }) => (
                        <PhoneInput
                          {...field}
                          inputExtraProps={{
                            ref,
                            required: true,
                            autoFocus: true,
                          }}
                          // isValid={(web_phone) => {
                          //   if (web_phone) {
                          //     setValue("web_phone", web_phone);
                          //     return true;
                          //   } else {
                          //     return false;
                          //   }
                          // }}
                          country={"us"}
                          // onlyCountries={["pak"]}
                          countryCodeEditable={false}
                          specialLabel={"Mobile Number"}
                        />
                      )}
                    />
                  </div>


                  <div
                    className={`text_felid col-md-6 ${errors.url ? "error" : ""
                      }`}
                  >
                    <label>
                      {errors.url ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.url?.message}
                        </>
                      ) : (
                        <>Company Website
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="position-relative text_felid">
                      <input
                        type="text"
                        {...register("url", { required: true })}
                        placeholder="Company Website"
                      />
                    </div>

                  </div>
                  <div className={`col-md-6 text_felid ${errors.address ? "error" : ""
                    }`}>
                    <label>
                      {errors.address ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.address?.message}
                        </>
                      ) : (
                        <>Company Address
                          {/* <span className="text-red-500">*</span>  */}
                        </>
                      )}
                    </label>
                    <div className="relative text_felid">
                      <input
                        type="text"
                        {...register("address", { required: true })}
                        placeholder="Company Address"
                      />
                      {/* <textarea {...register("address", { required: true })} placeholder="Company Address" className="form-control min-h-[150px!important] border-[#ccc]"></textarea> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-md-3`}>
                <div className={` selection_felid mb-3 ${errors.Tags ? "error" : ""}`}>
                  <label htmlFor="tags">
                    {errors.Tags ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors.tags.message}
                      </>
                    ) : (
                      <>
                        Tags
                        {/* <span className="text-red-500">*</span> */}
                      </>
                    )}
                  </label>

                  {/* <Addkeys
                      tags={keywords}
                      labelId="tags"
                      setTags={setTags}
                      setValue={setValue}
                      name="tags"
                      placeholder="Tags"
                    /> */}
                  <Createable setSelected={setSelected} selected={selected} options={options} />
                </div>
                <div
                  className={` text_felid ${errors.membership ? "error" : ""
                    }`}
                >
                  <label>
                    {errors.membership ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors?.membership?.message}
                      </>
                    ) : (
                      <>Company Membership</>
                    )}
                  </label>
                  <div className="position-relative text_felid">
                    <input
                      type="text"
                      {...register("membership", { required: true })}
                      placeholder="Company Membership"
                    />
                  </div>
                </div>
                <div
                  className={`text_felid ${errors.experince ? "error" : ""
                    }`}
                >
                  <label>
                    {errors.experince ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors?.experince?.message}
                      </>
                    ) : (
                      <>Company Experience</>
                    )}
                  </label>
                  <div className="relative text_felid">
                    <input
                      type="text"
                      {...register("experince", { required: true })}
                      placeholder="Company Experience"
                    />
                  </div>
                </div>

              </div>
              <div
                className={`col-md-9 text_felid ${errors.short_dec ? "error" : ""
                  }`}
              >
                <label>
                  {errors.short_dec ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.short_dec?.message}
                    </>
                  ) : (
                    <>
                      Company Short Description
                      {/* <span className="text-red-500">*</span> */}
                    </>
                  )}
                </label>
                <div className="position-relative text_felid">
                  <Editor name={'short_dec'} setValue={setValue} watch={watchs} Rowdata={Rowdata} state={values.short_dec} error={errors?.short_dec} clearErrors={clearErrors} />
                </div>
              </div>
              <hr className="mt-0" />
              <div className="col-md-12">
                <h5 className="mb-3 text-lg">
                  Company Ratings and Skills
                  {/* <span className="text-red-500">*</span> */}
                </h5>
              </div>
              <div className="col-md-12">
                <div className={`flex checkbox-group radio-group items-center text_felid ${errors.rating_skils ? "error" : ""
                  }`}>
                  {checkboxes.map((value, index) => {
                    return <div key={index}>
                      <label>
                        <input
                          type="checkbox"
                          id={value.id}
                          name={value.name}
                          // checked={value.checked}
                          value={value.id}
                          // {...register("rating_skils", {
                          //   required: true,
                          // })}
                          checked={selectedValues.includes(value.name)}
                          // defaultChecked={values?.rating_skils?.find((val)=> val.includes(value.name) ? true : false)}
                          onChange={(e) => handleCheckboxChange(e, index)}
                        // checked={
                        //   values?.rating_skils && values?.rating_skils?.filter((val)=> val.includes('a_rated')) 
                        // }
                        />
                        <span>{value.name}</span>
                      </label>
                    </div>
                  })}

                  {show && <div className="flex-1">
                    <input
                      type="number"
                      className="form-control text_felid"
                      value={values?.licensed_no}
                      {...register("licensed_no", { required: true })}
                      placeholder="If yes write license number"
                    />
                  </div>}
                </div>
              </div>
              <hr className="mt-0" />
              <div className="col-md-12">
                <h5 className="mb-3 text-lg">Company's Custom information</h5>
              </div>
              <div
                className={` col-md-3`}
              >
                <div
                  className={`text_felid  ${errors.custom_title ? "error" : ""
                    }`}
                >
                  <label>
                    {errors.custom_title ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors?.custom_title?.message}
                      </>
                    ) : (
                      <>Custom Title</>
                    )}
                  </label>

                  <div className="position-relative text_felid">
                    <input
                      type="text"
                      {...register("custom_title", { required: true })}
                      placeholder="Custom Title"
                    />
                  </div>
                </div>


              </div>
              <div
                className={`col-md-9 text_felid ${errors.custom_dec ? "error" : ""
                  }`}
              >
                <label>
                  {errors.custom_dec ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.custom_dec?.message}
                    </>
                  ) : (
                    <>
                      {/* Website Description* */}
                      Custom Description
                    </>
                  )}
                </label>
                <div className="position-relative text_felid">
                  <Editor name={'custom_dec'} setValue={setValue} watch={watchs} Rowdata={Rowdata} state={values.custom_dec} error={errors?.custom_dec} clearErrors={clearErrors} />

                </div>
              </div>
              <hr className="mt-0" />

              <div className="col-md-12">
                <hr className="mt-0" />
                <h5 className="mb-3 text-lg">
                  {/* Personal information */}
                  Owner's / Leader's Information
                </h5>
              </div>

              <div className={`col-md-3`}>
                <div className={`text_felid ${errors.leader_name ? "error" : ""}`}>
                  <label>
                    {errors.leader_name ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors?.leader_name?.message}
                      </>
                    ) : (
                      <>
                        Company Leader's Name
                        {/* <span className="text-red-500">*</span> */}
                      </>
                    )}
                  </label>
                  <div className="position-relative text_felid">
                    <input
                      type="text"
                      {...register("leader_name", { required: true })}
                      placeholder="Company Leader's Name"
                    />
                  </div>
                </div>
                <div className={`text_felid float-left w-full ${errors.leader_phone ? "error" : ""}`}>
                  <label>
                    {errors.leader_phone ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors?.leader_phone?.message}
                      </>
                    ) : (
                      <>
                        Company Leader's Phone
                        {/* <span className="text-red-500">*</span> */}
                      </>
                    )}
                  </label>
                  {/* <PhoneInput
                      country={"us"} // Set the default country
                      value={phoneNumber.leader_phone} // Set the phone number value
                      onChange={(val) => handleOnChange(val, "leader_phone")} // Handle changes to the phone number
                    /> */}
                  <Controller
                    control={control}
                    name="leader_phone"
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                      <PhoneInput
                        {...field}
                        inputExtraProps={{
                          ref,
                          required: true,
                          autoFocus: true,
                        }}
                        // isValid={(leader_phone) => {
                        //   if (leader_phone) {
                        //     setValue("leader_phone", leader_phone);
                        //   } else {
                        //     return false;
                        //   }
                        // }}
                        country={"us"}
                        // onlyCountries={["pak"]}
                        countryCodeEditable={false}
                        specialLabel={"Mobile Number"}
                      />
                    )}
                  />
                </div>
                <div className={`text_felid ${errors.leader_email ? "error" : ""}`}>
                  <label>
                    {errors.leader_email ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors?.leader_email?.message}
                      </>
                    ) : (
                      <>
                        {/* Name* */}
                        Company Leader's Email
                        {/* <span className="text-red-500">*</span> */}
                      </>
                    )}
                  </label>
                  <div className="position-relative text_felid">
                    <input
                      type="text"
                      {...register("leader_email", { required: true })}
                      placeholder="Company Leader's Email"
                    />
                  </div>
                </div>
                <div
                  className={`text_felid ${errors.leader_designation ? "error" : ""
                    }`}
                >
                  <label>
                    {errors.leader_designation ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors?.leader_designation?.message}
                      </>
                    ) : (
                      <>
                        Leader's Designation
                        {/* <span className="text-red-500">*</span> */}
                      </>
                    )}
                  </label>
                  <div className="position-relative text_felid">
                    <input
                      type="text"
                      {...register("leader_designation", { required: true })}
                      placeholder="Leader's Designation"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`col-md-9 text_felid ${errors.leader_msg ? "error" : ""
                  }`}
              >
                <label>
                  {errors.leader_msg ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.leader_msg?.message}
                    </>
                  ) : (
                    <>
                      Message from the leader
                      {/* <span className="text-red-500">*</span> */}
                    </>
                  )}
                </label>
                <div className="position-relative text_felid">
                  <Editor name={'leader_msg'} setValue={setValue} watch={watchs} Rowdata={Rowdata} state={values.leader_msg} error={errors?.leader_msg} clearErrors={clearErrors} />


                </div>
              </div>
              <div className="col-md-3">
                <label>
                  {errors.allow_email ? (
                    <><span className="text-red-500">
                      <i
                        className="fa fa-exclamation-triangle mr-2"
                        aria-hidden="true"
                      ></i>
                      {errors?.allow_email?.message}</span>

                    </>
                  ) : (
                    <>
                      Do you want to recieve emails from customer?
                      {/* <span className="text-red-500">*</span> */}
                    </>
                  )}
                </label>
              </div>
              <div className="col-md-9">

                <div
                  className={`flex radio-group items-center text_felid ${errors.allow_email ? "error" : ""
                    }`}
                >
                  <div>
                    <label>
                      <input
                        type="radio"
                        value={1}
                        name="allow_email"
                        onChange={OnSelect}
                        checked={parseInt(RadioBtn) === 1}
                      />
                      <span>Yes</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value={0}
                        name="allow_email"
                        onChange={OnSelect}
                        checked={parseInt(RadioBtn) === 0}
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>
              </div>
              <hr className="mt-4" />
              <div className="col-md-12">
                <h5 className="mb-3 text-lg">SEO Fields</h5>
              </div>
              <div className="col-md-3">

                <div
                  className={` text_felid ${errors.meta_title ? "error" : ""}`}
                >
                  <label>
                    {errors.meta_title ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors?.meta_title?.message}
                      </>
                    ) : (
                      <>
                        Meta Title <span className="text-red-500">*</span>
                      </>
                    )}
                  </label>
                  <div className="position-relative text_felid">
                    <input
                      type="text"
                      {...register("meta_title", { required: true })}
                      placeholder="Meta Title"
                    />
                  </div>
                </div>
                <div className={` text_felid ${errors.meta_keywords ? "error" : ""}`}>
                  <label htmlFor="meta_keywords">
                    {errors.meta_keywords ? (
                      <>
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        {errors.meta_keywords.message}
                      </>
                    ) : (
                      <>
                        Meta Keywords (Comma / Press Enter) <span className="text-red-500">*</span>
                      </>
                    )}
                  </label>

                  <Addkeys
                    tags={keywords}
                    labelId="meta_keywords"
                    setTags={setTags}
                    setValue={setValue}
                    name="meta_keywords"
                    placeholder="Meta Keywords"
                  />
                </div>
              </div>
              <div
                className={`col-md-9 text_felid ${errors.meta_dec ? "error" : ""
                  }`}
              >
                <label>
                  {errors.meta_dec ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.meta_dec?.message}
                    </>
                  ) : (
                    <>
                      Meta Description <span className="text-red-500">*</span>
                    </>
                  )}
                </label>
                <div className="position-relative text_felid">
                  <Editor name={'meta_dec'} setValue={setValue} watch={watchs} Rowdata={Rowdata} state={values.meta_dec} error={errors?.meta_dec} clearErrors={clearErrors} />


                </div>
              </div>
              <hr className="mt-0" />
              <div className="col-md-12">
                <h5 className="mb-3 text-lg">Company Reviews</h5>
              </div>
              <div
                className={`col-md-6 text_felid ${errors.author ? "error" : ""
                  }`}
              >
                <label>
                  {errors.author ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.author?.message}
                    </>
                  ) : (
                    <>Author</>
                  )}
                </label>
                <div className="position-relative text_felid">
                  <input
                    type="text"
                    {...register("author", { required: true })}
                    placeholder="Author"
                  />
                </div>
              </div>
              <div
                className={`col-md-6 text_felid ${errors.author_review ? "error" : ""
                  }`}
              >
                <label>
                  {errors.author_review ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.author_review?.message}
                    </>
                  ) : (
                    <>Reviews</>
                  )}
                </label>
                <div className="position-relative text_felid">
                  <input
                    type="text"
                    {...register("author_review", { required: true })}
                    placeholder="Reviews"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <h5 className="mb-3 text-lg">Company Ratings </h5>
              </div>
              <div className="col-md-2 text_felid w-auto flex-1">
                <label className="text-capitalize">(5) star rating</label>
                <div className="position-relative text_felid">
                  <input
                    type="number"
                    {...register("r5", { required: true })}
                    placeholder="Five star rating"
                  />
                </div>
              </div>
              <div className="col-md-2 text_felid w-auto flex-1">
                <label className="text-capitalize">(4) star rating</label>
                <div className="position-relative text_felid">
                  <input
                    type="number"
                    {...register("r4", { required: true })}
                    placeholder="Four star rating"
                  />
                </div>
              </div>
              <div className="col-md-2 text_felid w-auto flex-1">
                <label className="text-capitalize">(3) star rating</label>
                <div className="position-relative text_felid">
                  <input
                    type="number"
                    {...register("r3", { required: true })}
                    placeholder="Three star rating"
                  />
                </div>
              </div>
              <div className="col-md-2 text_felid w-auto flex-1">
                <label className="text-capitalize">(2) star rating</label>
                <div className="position-relative text_felid">
                  <input
                    type="number"
                    {...register("r2", { required: true })}
                    placeholder="Two star rating"
                  />
                </div>
              </div>
              <div className="col-md-2 text_felid w-auto flex-1">
                <label className="text-capitalize">(1) star rating</label>
                <div className="position-relative text_felid">
                  <input
                    type="number"
                    {...register("r1", { required: true })}
                    placeholder="One star rating"
                  />
                </div>

              </div>
              <div className="col-md-12 mb-4">
                <button
                  type="submit"
                  className="bg-green-500 px-5 py-2 border-0 rounded-md text-white"
                >
                  {Rowdata.id ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default ListingForm;
