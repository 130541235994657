import * as yup from "yup";
export const schemas = yup.object().shape({
  ////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  title: yup.string().required("Title is Required!"),
  // page_dec: yup.string().required("Description is Required!"),
  // tags: yup
  //   .array()
  //   .min(1, "Add Min 1 tags!")
  //   .max(10, "Add Max 10 meta tags!")
  //   .of(
  //     yup.object().shape({
  //       label: yup.string().nullable().required(),
  //       value: yup.string().nullable().required(),
  //     })
  //   )
  //   .nullable()
  //   .required("meta tags is Required!"),
  // meta_title: yup.string().required("Meta Title is Required!"),
  // meta_dec: yup.string().required("Meta Discription is Required!"),
  // meta_keywords: yup
  //   .array()
  //   .min(1, "Add Min 1 keywords!")
  //   .max(5, "Add Max 10 meta keywords!")
  //   .of(
  //     yup.object().shape({
  //       label: yup.string().nullable().required(),
  //       value: yup.string().nullable().required(),
  //     })
  //   )
  //   .nullable()
  //   .required("meta keywords is Required!"),
  
});
