import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Binary } from "./binary";
import axios from "axios";
import Editor from "../components/editor";
// import Addkeys from "../widgets/tags";
import { schemas } from "./blogpost_schema";
import { toast } from "react-toastify";
import { useMemo } from "react";
// import Createable from "./Createable";

function BlogPost({ setshowOverlay, Rowdata, setRefresh }) {

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemas),
    mode: "onChange",
  });
  const values = getValues();
  const watchs = watch();


  const onSubmit = async (datas) => {
    const sendData = {
      id: Rowdata.id ? Rowdata.id : "",
      title: datas.title.replaceAll(" ", "-").toLowerCase(),
      meta_title: datas?.meta_title,
      description: JSON.stringify(datas.description),
      meta_disc: datas?.meta_disc,
      image: datas.service,
    };

    const header_options = {
      method: Rowdata.id ? "PUT" : "POST",
      mode: "cors",
      url: `${process.env.REACT_APP_BaseURL}/api/blog/blog`,
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: sendData,
    };

    axios
      .request(header_options)
      .then(function (responce) {
        if (responce?.status === 201) {
          toast.success("Website Blog content created sucessfully!");
          setshowOverlay(false);
          setRefresh(true);
          reset();
        } else if (responce?.status === 200) {
          toast.success("Website Blog content created sucessfully!");
          setshowOverlay(false);
          setRefresh(true);
          reset();
        }
      })
      .catch(function (error) {
        if (error?.response?.status === 409) {
          toast.error(error?.response?.data);
          // setError("url", {
          //   message:
          //     "You already added this website on backlinkerr please change website URL",
          // });
        } else if (error?.response?.status === 500) {
          toast.error(`Internal Server Error ${error}`);
        }
      });
  };

  useEffect(() => {
    if (Rowdata.id) {
      const { id, title, meta_disc, meta_title, description, image } = Rowdata;
      const data = Rowdata.description && JSON.parse(Rowdata.description)
      setValue("description", data);
      setValue("id", id);
      setValue("title", title.replaceAll("-", " "));
      setValue("meta_disc", meta_disc);
      setValue("meta_title", meta_title);
    }
  }, [Rowdata.id]);



  return (
    <>
      <div className="py-0.5">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <h4>Blog Post</h4>
                <p>Please fill the all compulsory fields</p>
              </div>
              <hr className="my-4" />
              <hr className="mt-0" />
              <div
                className={`col-md-3 ${errors.service ? "Image-error" : ""}`}
              >
                <Binary
                  image={Rowdata.image}
                  Rowdata={Rowdata}
                  name="service"
                  clearErrors={clearErrors}
                  setValue={setValue}
                  errors={errors.service}
                />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div
                    className={`col-md-6 text_felid ${errors.title ? "error" : ""
                      }`}
                  >
                    <label>
                      {errors.title ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.title?.message}
                        </>
                      ) : (
                        <>
                          {/* Website title */}
                          Title <span className="text-red-500">*</span>
                        </>
                      )}
                    </label>
                    <div className="position-relative text_felid">
                      <input
                        type="text"
                        {...register("title", { required: true })}
                        placeholder="Title"
                      />
                    </div>
                  </div>
                  <div
                    className={`col-md-6 text_felid ${errors.meta_disc ? "error" : ""
                      }`}
                  >
                    <label>
                      {errors.meta_disc ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.meta_disc?.message}
                        </>
                      ) : (
                        <>
                          Meta Description
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="position-relative text_felid">
                      <input
                        type="text"
                        {...register("meta_disc", { required: true })}
                        placeholder="Meta Description"
                      />
                    </div>
                  </div>
                  <div
                    className={`col-md-6 text_felid ${errors.meta_title ? "error" : ""
                      }`}
                  >
                    <label>
                      {errors.meta_title ? (
                        <>
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {errors?.meta_title?.message}
                        </>
                      ) : (
                        <>
                          Meta Title
                          {/* <span className="text-red-500">*</span> */}
                        </>
                      )}
                    </label>
                    <div className="position-relative text_felid">
                      <input
                        type="text"
                        {...register("meta_title", { required: true })}
                        placeholder="Meta Title"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`col-md-12 text_felid ${errors.description ? "error" : ""
                  }`}
              >
                <label>
                  {errors.description ? (
                    <>
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                      {errors?.description?.message}
                    </>
                  ) : (
                    <>
                      Page Description
                      {/* <span className="text-red-500">*</span> */}
                    </>
                  )}
                </label>
                <div className="position-relative text_felid">
                  <Editor
                    name={"description"}
                    setValue={setValue}
                    state={values?.description && values?.description}
                  // error={errors?.description}
                  // clearErrors={clearErrors}
                  />
                </div>
              </div>
              <hr className="mt-0" />
              <div className="col-md-12 mb-4">
                <button
                  type="submit"
                  className="bg-green-500 px-5 py-2 border-0 rounded-md text-white"
                >
                  {Rowdata.id ? "Update" : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default BlogPost;
