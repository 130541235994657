import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import BlogPost from "../components/Blogpost";
import axios from "axios";
import { toast } from "react-toastify";
import Overlay from "../components/overlay";
import moment from "moment";

export default function Blog() {
  const [showoverlay, setshowOverlay] = useState(false);
  const handleClose = () => setshowOverlay(false);
  const [Refresh, setRefresh] = useState(false);
  const [rawdata, setrawDate] = useState(null);
  const [Rowdata, setRowdata] = useState([]);
  const columnDefs = [
    {
      field: "id",
      headerName: "Id",
      filter: "agNumberColumnFilter",
      suppressMenu: false,
      minWidth: 100,
      flex: 0,
      width: 100,
      suppressSizeToFit: false,
    },
    {
      field: "title",
      headerName: "Name",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      cellRenderer: (params) => {
        return (
          <span>{params.value.charAt(0).toUpperCase() + params.value.slice(1).replaceAll("-", " ")}</span>
        )
      },
    },
    {
      field: "images",
      headerName: "Images",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      cellRenderer: (params) => <img src={process.env.REACT_APP_BaseURL === "development" ? params.value ? `${process.env.REACT_APP_BaseURL}/extraimages/blog/${params.value}` : "" : params.value ? `${process.env.REACT_APP_BaseURL}/public/extraimages/blog/${params.value}` : ""} alt={params.value} />,
      minWidth: 150,
      // flex: 1,
      maxWidth: 200,
    },
    {
      field: "date_created",
      headerName: "Posted Date",
      filter: "agNumberColumnFilter",
      // flex: 1,
      suppressMenu: false,
      cellRenderer: (params) => {
        return (
          <span>
            {moment(params.value).format("DD/MM/YYYY ")}
          </span>
        )
      },
      suppressSizeToFit: false,
      // maxWidth: 200,
    },
    {
      field: "status",
      headerName: "Action",
      floatingFilter: false,
      suppressMenu: false,
      flex: 1,
      cellRenderer: (params) => ActionButtons(params),
      // minWidth: 350,
      // maxWidth: 400,
      suppressSizeToFit: true,
    },
    
  ];
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      resizable: false,
      filter: true,
      flex: 1,
      floatingFilter: true,
      width: 100,
      minWidth: 100,
    };
  }, []);

  const handleShow = () => {
    setshowOverlay(true);
    setRowdata([])
  }

  const ActionButtons = (params) => {
    const Edit = () => {
      setshowOverlay(true)
      setRowdata(params.data)
      // console.log(params)
    }
    const Remove = async () => {
      const { id } = params.data
      const data = {
        id: id
      }

      await axios.delete(`${process.env.REACT_APP_BaseURL}/api/blog/blog`, { data }).then((val) => {
        toast.success("successfully Remove")
        setRefresh(val)
      });
    }

    return <>
      <button type="button" onClick={Edit} className="px-2 py-1.5 bg-yellow-500 inline-flex rounded-md mr-2 text-white"><i className="fa fa-pencil"></i></button>
      <button type="button" onClick={Remove} className="px-2 py-1.5 bg-red-500 inline-flex rounded-md mr-2 text-white"><i className="fa fa-times"></i></button>
    </>
  }


  const listingData = async () => {
    const api = await fetch(`${process.env.REACT_APP_BaseURL}/api/blog/blog`);
    const res = await api.json()
    setrawDate(res);
  }
  useEffect(() => {
    listingData();
  }, [Refresh])

  return (
    <>
      <div className="p-4 grid grid-cols-12">
        <div className="column col-span-12 lg:col-span-8 mb-4">
          <h3>Add Blogs</h3>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <div className="text-lg-end">
            <button type="button" className="btn-normal btn-green min-w-[10%] py-[10px] text-white" onClick={handleShow}>Create Post</button>
          </div>
        </div>

        <Overlay showoverlay={showoverlay} handleClose={handleClose} data={<BlogPost Rowdata={Rowdata} setshowOverlay={setshowOverlay} setRefresh={setRefresh} />} />
        <div className="col-span-12">
          <AgGridReact
            className="ag-theme-alpine h100"
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rawdata}
            pagination={true}
            alwaysShowHorizontalScroll={false}
            alwaysShowVerticalScroll={false}
            
          />
        </div>
      </div>
    </>
  )
}
